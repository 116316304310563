.HtmlItemDialog {
  top: calc(50% - 280px);
  width: 44.8vw;
  height: 56.8vh;
  margin-left: -430px;
}

.HtmlItemDialog {
  border-top: 0;
  outline: none;
}

.htmlVarsButton {
  font-size: 11px !important;
  padding: 0 6px !important;
  border-radius: 2px !important;
  background-color: #2283c5 !important;
  margin-left: 3px;
}