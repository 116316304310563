.inactive-project-form {
    padding: 20px 10px 0;
    text-align: center;
    word-break: normal;
}

.inactive-project-form aside {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 20px 80px 0 80px;
}

.inactive-project-form .btn-fix {
    padding: 0;
}

.inactive-project-form p {
    font-size: 1.2rem;
}

.inactive-project-form label {
    margin-bottom: 15px;
}

.inactive-project-form select,
.inactive-project-form button {
    font-family: inherit !important;
    margin: 0;
    border-radius: 0;
}

.inactive-project-form .btn-exit button.btn.btn-primary.enable {
    background-color: #ef3558 !important;
}

.inactive-project-form .btn-exit button.btn.btn-primary.enable:hover {
    background-color: #bf2a46 !important;
}

.inactive-project-form button.btn.btn-primary.enable {
    width: 80px;
    border-radius: 2px;
}

.inactive-project-form .select-project {
    padding: 20px;
}

.inactive-project-form .activate-project {
    padding-bottom: 20px;
}