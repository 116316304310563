.cpt-item-view-more {
    width: 100px;
    height: 82px;
}

.cpt-item-view-more-div {
    margin: 10px;
}

.cpt-item-view-more-name {
    height: 32px;
}

.orgmap-menu {
    width: 300px;
    margin: 0;
    position: relative;
}

.orgmap-menu.open {
    display: block;
}

.orgmap-menu .view-more-painels-title {
    line-height: 0;
    font-size: 0;
}

.links-container {
    max-height: 300px;
    overflow-y: auto;
}