.store {
  width: 100vw;
  height: calc(100vh - 52px);
  overflow: hidden;
  border: none;
}

.loading {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh - 52px);
}
