.hidden-filter {
  opacity: 0.5;
}

.user-filter-config-icon .Icon {
  font-size: 15px;
}

.DashboardFilter .FilterIconDraggableIcon {
  color: #ccc;
}

.DashboardFilter .nodis {
  padding-left: 0 !important;
  display: flex;
  align-items: center;
}

.user-filter-config-icon.ConfigFilterGear {
  vertical-align: top;
  padding-right: 5px;
}
