.MobileMenuTitle {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

.MobileMenuItem:not(.themed-component-hover) {
    display: flex;
    padding: 6px 0;
    font-weight: 400;
    justify-content: space-between;
    border-bottom: 1px solid #E7E8EB;
}

.MobileMenu .AccordionSideMenu.MobileMenuAccordion  {
    box-shadow: 0 0  transparent !important;
}

.MobileMenuList .ActionListItem.ItemSelected {
    box-shadow: inset 0 0 0 1px #005dff;
    border-radius: 4px;
}

.MobileMenuItemView .bng-search .search-button {
    right: 10px;
}

.ActionList.MobileMenuList .ActionListItems {
    min-height: 40vh;
    max-height: 55vh;
}

.ActionList.MobileMenuList .ActionListItem.active {
    cursor: pointer;
}

.MobileToggleItem {
    cursor: pointer;
}

.ActionListItem.inactive .MobileToggleItem,
.ActionListItem.active .MobileToggleItem:hover {
    opacity: 0.5;
}

.ActionListItem.active .MobileToggleItem,
.ActionListItem.inactive .MobileToggleItem:hover {
    opacity: 1;
}

.MobileMenu.ObjectRightMenuAccordion {
    z-index: 49;
    overflow: hidden;
}

.MobileMenuItemView .bng-button {
    width: 100%;
    margin-top: 20px;
    background-color: white;
    color: black;
    border: solid 1px black;
}