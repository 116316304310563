.AccAddonsTab {
  overflow-y: auto;
  display: grid;
  column-gap: 5px;
  margin: 0 15px;
  grid-template-columns: repeat(2, 1fr);
}

.AddonComponent {
  align-items: flex-start;
  display: block;
  padding: 10px 0;
  margin-left: 10px;
}

.addonBorder {
  border-radius: 4px 4px 0 0;
  height: 140px;
  padding: 15px;
  width: 410px;
}

@media (max-width: 860px) {
  .AccAddonsTab {
    grid-template-columns: repeat(1, 1fr);
  }
}
