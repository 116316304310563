.nav-bar-button > .dropdown-toggle {
    text-align: center;
}

.nav-bar-button > .dropdown-toggle > [class*="icon-"] {
    line-height: 35px;
}

.menu-bottom-btn {
    text-align: center;
    padding: 5px 0;
    background-color: #fff;
}

.nav-bar-button .menu_sttings .Icon {
    height: 16px;
    border-radius: 2px;
    margin-right: 5px;
    font-size: 22px;
    display: inline-block;
    width: 26px;
    vertical-align: top;
    margin-top: -2px;
}

.menu_sttings span.label.label-important.label-beta.pull-right {
    font-size: 10px !important;
    margin: 0 !important;
    text-shadow: none !important;
    letter-spacing: .5px;
    padding: 3px 8px !important;
    width: auto;
    height: auto !important;
    font-family: 'Roboto', monospace !important;
}

.dropdown-navbar.dropdown-menu li.menu_divider:hover {
    background: #ffffff !important;
}


.nav-bar-button .user-menu-display-name {
    float: left;
    width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.item-project-menu.disabled {
    cursor: not-allowed;
}

.item-project-menu-logo {
    width: 16px;
    height: 16px;
    margin: 5px 10px 5px 5px;
}

.item-project-link {
    text-decoration: none;
    display: inline-block;
    background-color: transparent !important;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.item-project-menu a.item-project-link:hover {
    background-color: white !important;
}

.favorite-project-link.btn-link {
    font-size: 15px;
    padding: 0 !important;
    margin: 0 !important;
}

li.project-li {
    padding: 0 !important;
}

.item-project-menu-opts {
    width: 74px;
}

.item-project-menu span.badge.badge-square {
    margin: 0px 8px;
}

.project-list {
    height: 276px;
    background: #f7f8fa;
}

.nav-bar-button.dropdown-menu a.item-link {
    background: transparent !important;
    color: #555555 !important;
    cursor: default !important;
    padding: 4px !important;
    line-height: 27px !important;
    font-weight: 500 !important;
    letter-spacing: .4px;
    cursor: pointer !important;
}

.nav-bar-button.dropdown-menu a.item-link.shared {
    padding-top: 6px !important;
    padding-bottom: 9px !important;
}

.link-color {
    color: #08C;
}

.nav-bar-button.dropdown-menu .no-padding {
    padding: 0;
}

.nav-search-input-parent {
    margin-top: 3px;
}

.nav-search-input-parent > input {
    width: 175px;
    margin: 0;
}

.nav-search-advanced-link {
    width: 120px;
    float: right;
    font-size: 13px;
    margin-top: 3px;
    cursor: pointer;
    text-align: right;
}

i.nav-search-button-on-input.material-icons {
    color: #ffffff;
    top: 12px;
    position: absolute;
}

i.nav-search-button.material-icons {
    color: #ffffff;
    padding: 14px 4px 0px 0px;
}

i.nav-search-close-button-on-input.material-icons,
i.nav-search-button.material-icons {
    cursor: pointer
}

.sched-name {
    width: 260px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    position: absolute;
    margin-top: -1px;
}

button.btn-only-icon.delete-only-icon {
    margin: 0 2px !important;
}


.sched-btn:first-child {
    margin-left: 0;
}

.navbar .btn.sched-btn {
    height: 20px;
    width: 20px;
    padding: 0 0 1px 1px;
    margin: 0 0 0 4px;
    border-radius: 2px;
    border: 0;
    text-shadow: none !important;
}

.navbar .nav-search-input {
    height: 25px;
    margin-top: 2px;
}

/*css novo*/
.navbarLogo {
    margin: 6px 10px;
    height: 30px;
}

/*container-fluid remover padding geral*/
.navbar-new .container-fluid {
    padding: 0;
}

/*fim dos gerais*/

.btn-new.cpt-menu {
    padding: 0px 8px;
    border: 0;
    border-top: 0 !important;
}

.btn-new.cpt-menu.disable {
    background: rgba(255, 255, 255, .25) !important;
}

.btn-enable-cockpit {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-disable-cockpit {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-new.cpt-menu i {
    font-size: 20px;
}

.nav-bar-button > .dropdown-toggle > i {
    line-height: 50px;
    color: rgba(255, 255, 255, 0.9);
}

.navbar-new .navbar-inner {
    height: 48px;
}

.ace-nav > li > a > .badge {
    position: absolute;
    padding: 3px 4px 2px;
    margin-top: 6px;
    margin-left: -10px;
    font-size: 11px;
}

.ace-nav > li > a > .badge.badge-grey {
    background: #3A4045 !important;
    color: #ffffff;
    min-width: 10px;
    display: none;
}

.ace-nav > li > a > .badge.badge-important {
    background: #E24C38 !important;
    color: #ffffff;
    min-width: 10px;
    text-shadow: none;
}


.nav-bar-button {
    padding: 0px 12px;
}

.nav-bar-button.no-badge {
    padding: 0px 10px;
}

.nav-bar-button .btn-new {
    margin-top: 9px;
}

.nav-bar-button .user-menu-avatar {
    width: auto;
    height: 34px;
    border-radius: 100%;
}

.navBarSeparator {
    box-sizing: border-box;
    height: 48px;
    width: 1px;
    border-right: 1px solid rgba(0, 0, 0, .1);
    margin: 0 8px;
}

.type-view-buttons {
    position: relative;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    transition: all .6s ease;
    height: 48px;
    opacity: 1;
}

.hidden-top {
    top: -25px;
    opacity: 0;
    position: fixed;
}

.type-view-buttons.hide {
    opacity: 0;
    margin-top: -40px;
}

.type-view-button {
    padding: 0 6px;
    margin: auto 0 auto 22px;
    opacity: .7;
    color: #ffffff;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 20px;
    cursor: pointer;
}

.type-view-button.active {
    border-bottom: 2px solid #ffffff;
    opacity: 1;
}

li.nav-bar-button.cockpit-header-buttons {
    display: none;
}

ul.pull-right.dropdown-navbar.dropdown-menu.dropdown-caret.dropdown-close {
    border: none;
    border-radius: 4px !important;
    padding: 0;
}

.dropdown-navbar.dropdown-menu > li.nav-header {
    text-transform: uppercase;
    letter-spacing: .5px;
    height: 32px;
    line-height: 30px;
    padding: 7px 14px;
    white-space: nowrap;
    background: #ffffff !important;
}

.dropdown-navbar.dropdown-menu > li {
    background: #f7f8fa;
    padding: 0;
}

.dropdown-menu > li.no-hover {
    background: #ffffff;
}

.dropdown-navbar.dropdown-menu li.menu_divider {
    background: #f7f8fa !important;
    padding: 3px 20px 3px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, .1) !important;
}

.dropdown-navbar.dropdown-menu li.menu_divider:last-child {
    border-bottom: 0 !important;
}

.dropdown-navbar.dropdown-menu .menu-bottom-btn .btn {
    font-size: 12px;
    text-shadow: none;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .3px;
    margin-top: 0;
}

.dropdown-navbar.dropdown-menu > li.nav-header .btn {
    z-index: 2;
    position: absolute;
    top: 10px;
    width: 70px;
    right: 10px;
    text-shadow: none !important;
    border: 0;
    font-weight: bold;
    letter-spacing: .3px;
    font-size: 12px;
    text-transform: uppercase;
}


button.btn.btn-primary.favorite-project-link.btn-link.disable {
    color: #565657 !important;
}

i.publisher-fragment-icon {
    font-size: 18px;
    color: #6e6e6e;
}

.row-fluid.publisher-item-container {
    display: flex;
}

.publisher-buttons {
    width: 65px;
}

.publisher-item {
    width: 340px;
    padding: 3px 0px 0px 10px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
}

.publisher-item.publisher-item-author {
    font-size: 11px;
    letter-spacing: .3px;
    line-height: 15px;
    margin: 6px 0;
    font-weight: normal;
}

.publisher-item-container {
    margin-top: 2px;
}

.publisher-item.publisher-item-author i.material-icons {
    font-size: 16px;
    margin-right: 6px;
    color: #6e6e6e !important;
}

.publisher-buttons button {
    padding: 3px 5px;
    margin: 2px;
}

a.publisher-item-link:focus,
a.publisher-item-link:hover {
    background: #ffffff;
    background-image: none !important;
}

i.publisher-fragment-icon:before {
    width: 18px;
}

a.publisher-item-link {
    cursor: inherit;
}

.navbar .btn.square-mini-btn {
    height: 20px;
    width: 20px;
    padding: 0 0 1px 1px;
    margin: 0 0 0 4px;
    border-radius: 2px;
    border: 0;
    text-shadow: none !important;
}

.user-menu-option {
    display: flex;
    color: #7e7e7e;
    cursor: pointer;
    border-bottom: 1px solid #ededed;
    height: 48px;
}

.user-menu-option-color {
    width: 4px;
    background: #269C59;
}

.user-menu-option-labs .user-menu-option-color {
    background: #EB354D;
}

.user-menu-option-manage .user-menu-option-color {
    background: #EAB23A;
}

.user-menu-option-news .user-menu-option-color {
    background: #a954c1;
}

.user-menu-option-help .user-menu-option-color {
    background: #21879b;
}


.user-menu-option-icon {
    padding: 4px 10px;
}

.user-menu-option-label {
    padding: 4px 10px 4px 0px;
}

.user-menu-option-icon i.material-icons {
    font-size: 20px;
    padding: 10px 0px;
}

.user-menu-option-label {
    padding: 14px 0px;
    font-size: 12px;
    font-weight: bold;
}

.user-menu-option:hover {
    background: #fff;
}

.menu-bottom-btn:hover {
    background: #fff;
}

.type-view-buttons-animation-enter {
    opacity: 0.01;
    transform: translateY(-50%);
}

.type-view-buttons-animation-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 500ms ease;
}

.type-view-buttons-animation-exit {
    opacity: 1;
    transform: translateY(0%);
}

.type-view-buttons-animation-exit-active {
    opacity: 0.01;
    transform: translateY(-50%);
    transition: all 500ms ease;
}

.nav-bar-button .dropdown-toggle {
    position: relative;
}

.nav-bar-button .MenuAlertContainer {
    position: absolute;
    display: inline-block;
    right: 16px;
    color: #ff7f50;
    background-color: white;
    box-shadow: 0 0 2px #333;
    border-radius: 100%;
    height: 20px;
    width: 20px;
}

.nav-bar-button .AlertContainer {
    position: absolute;
    bottom: 5px;
    right: -8px;
    background-color: white;
    border-radius: 100%;
    height: 16px;
    width: 16px;
    box-shadow: 0 0 2px #333;
    z-index: 1;
}

.UserMenuAvatarContainer .AlertContainer {
    bottom: -3px;
    right: -5px;
}

.nav-bar-button .AlertContainer .Icon {
    line-height: 16px;
    font-size: 16px;
    z-index: 1;
}

.type-view-button.active.disabled {
    cursor: default;
}

.type-view-button.hover:hover {
    /*color: #f5f5f5;*/
    opacity: 1;
}

.scrollbar-macosx.project-list {
    height: 276px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    scrollbar-width: none;
}

.ScrollContainer.scrollbar-macosx.notificationsTable-container {
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    margin-bottom: 0;
    margin-right: 0;
    max-height: 607px;
}

.user-menu-option-tag {
    align-items: center;
    display: flex;
}