.UserActivationPage .UserMessage {
  max-height: 40vh;
  overflow: auto;
}

.UserActivationPage .UserMessage blockquote {
  padding: 0;
  margin: 0;
  border-left-width: 10px;
  border-left-color: transparent;
  white-space: pre;
  font-size: 16px;
}
