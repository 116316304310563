.PublicLinkListDialog .widget-main {
    padding-bottom: 20px;
}

.PublicLinkListDialog .widget-body .table {
    border-top: 0;
    border-bottom: 1px solid #E4E4E4;
}

.PublicLinkListDialog .scroll-bar-object-select {
    border: 1px solid #E4E4E4;
    border-right: 0;
    border-left: 0;
    min-height: 250px;
    max-height: 250px;
}

.PublicLinkListDialog .button-add-public-link-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.PublicLinkListDialog .button-add-public-link {
    color: #373737;
    padding: 0 3px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin: auto 7px 7px auto;
}

.PublicLinkListDialog .button-add-public-link:hover {
    background-color: #eee;
}