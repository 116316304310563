.KeyFieldSelector {
    justify-content: right;
}

.KeyFieldSelector .Label {
    white-space: nowrap;
}

.KeyFieldSelector .bng-button {
    width: 150px !important;
    height: 25px !important;
    padding: 0 !important;
}

.KeyFieldSelector .HelpIcon {
    font-size: 19px;
    color: #888888;
    cursor: help;
}


/* Dropdown */
.KeyFieldSelectorDropPopper .FilterMembers {
    border: 1px solid #efefef;
    border-radius: 4px;
    padding: 6px;
}

.KeyFieldSelectorDropPopper .FilterMembers .MembersContainer {
    position: relative;
    overflow: auto;
    padding: 0 4px;
}

.KeyFieldSelectorDropPopper .FilterMembers .bng-search {
    height: 30px;
}

.KeyFieldSelector .MemberInfo .BngInput {
    background-color: white !important;
}

.KeyFieldSelectorDropPopper .AddButton {
    width: 100% !important;
}

.KeyFieldSelectorDropPopper .FilterMembers .MembersContainer .lbl {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.KeyFieldSelector .KeyFieldContainer {
    gap: 5px;
}

.KeyFieldSelector .KeyField {
    white-space: nowrap;
}