.OrgmapPropertiesDialog .row-fluid {
    display: flex;
    align-items: center;
    min-height: 32px;
}

.OrgmapPropertiesDialog .row-fluid > *:first-child {
    width: 100%;
}

.OrgmapPropertiesDialog .BngInputColor {
    margin-right: 10px;
}