.PermissionDialog .ContentContainer {
    position: relative;
    height: 302px;
}

.PermissionDialog .ContentContainer.Private{
    height: 300px;
}

.PermissionDialog .BngEmpty .Container {
    width: 400px;
}

.PermissionDialog .BngEmpty .Image {
    width: 200px;
    height: unset;
}

.PermissionDialog .TableContainer {
    max-height: 255px;
    overflow: auto;
}

.PermissionDialog .Filter {
    border-bottom: 1px #f7f7f7 solid;
    margin: 5px 8px;
}

.PermissionDialog .Filter input,
.PermissionDialog .Filter select {
    margin: 0 !important;
    border: none;
}

.PermissionDialog .ReplicateToChildrenContainer {
    margin-top: 15px;
}

.PermissionDialog .SelectedObjectsWrapper {
    display: flex;
    align-items: center;
    width: 90%;
    max-width: 90%;
    overflow: scroll;
}

.PermissionDialog .SelectedObject {
    background: #005DFF;
    margin: 5px;
    border-radius: 25px;
    color: #FFFFFF;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    white-space: nowrap;
    line-height: 24px;
}

.PermissionDialog .objectName {
    text-overflow: ellipsis;
    max-width: 125px;
    overflow: hidden;
    padding: 0 5px;
}