.StyleWrapper {
  margin: 35px;
}

.ApplicationTheme {
}

.ApplicationTheme > h6,
.PaletteColor > h6 {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ApplicationThemeDescription,
.ApplicationPaletteColorDescription,
.DefaultPrintLayoutDescription {
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ApplicationThemeOptions,
.DefaultTableLayoutOptions,
.DefaultPrintLayout,
.PrintingOptions,
.HeaderFontStyles {
  margin-top: 15px;
}

.ApplicationThemeOptions > input,
.DefaultTableLayoutOptions > input {
  background-repeat: no-repeat;
  width: 190px;
  height: 190px;
  background-size: 190px;
  border-radius: 8px;
  border: 1px solid #e4e4e4;
  margin: 0 50px 15px 0;
}

.ApplicationThemeOptions > input:global(.selected),
.DefaultTableLayoutOptions > input:global(.selected) {
  border: 2px solid #005dff;
}

.ApplicationThemeOptions > input:hover,
.DefaultTableLayoutOptions > input:hover {
  box-shadow: 0 0 10px #005dff;
}

.PaletteColor {
}

.PaletteColorsWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.PaletteColorsWrapper > div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.PaletteColorsWrapper > div > div {
  display: flex;
  flex-direction: row;
  color: #333;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex-wrap: wrap;
}

.PaletteColorsWrapper > div > div:global(.customized-palette-color) {
  margin-bottom: 15px;
}

.PaletteColorsWrapper > div > div:global(.BngField) {
  display: flex;
  flex-direction: column;
}

.PaletteColorsWrapper > div :last-child > div:global(.BngDropdown.BngColorPickerDropdown) {
  margin: 0 15px 15px 0;
  height: 35px !important;
  width: 35px !important;
  border-radius: 20px;
}

.PaletteColorsWrapper > div :last-child > div {
  margin: 0 20px 20px 0;
  height: 30px;
  width: 30px;
  border-radius: 20px;
}

.PaletteColorsWrapper > div :last-child > div:global(.BngDropdown.BngColorPickerDropdown) > div > button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #005dff;
  height: 30px;
  width: 30px;
}

.PaletteColorsWrapper > div :last-child > div:global(.BngDropdown.BngColorPickerDropdown) > div > button > i {
  color: #005dff;
}

.DefaultTableLayout {
}

.DefaultTableLayoutOptions {
}

.DefaultTableLayoutOptions > input {
  height: 80px;
  width: 160px;
  background-repeat: no-repeat;
}

.LayoutOptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.LayoutOptions > div,
.PrintingOptions > div {
  width: 49%;
}

.DefaultPrintLayout {
}

.PrintingOptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.PageOptions {
  display: flex;
  flex-direction: row;
}

.PageOptions > div {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.HeaderFontStyles > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.HeaderFontStyles > div > div {
  width: 33%;
}

.HideField {
  display: none;
}

.ToggleColorsListButton {
  background-color: #f7f8fa;
  color: #337dff;
  width: fit-content;
}

.ToggleColorsListButton:hover {
  cursor: pointer;
}
