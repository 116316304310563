.NotificationMenuButton.dropdown-menu {
    width: 370px;
}

.NotificationMenuButton .notification-list-content {
    width: 365px;
    padding: 18px 14px 0 14px;
}

.checkbox .lbl {
    cursor: pointer;
}

.nav-notification-filters .checkbox:first-child {
    padding-left: 0;
}

.nav-notification-filters .checkbox {
    padding-left: 10px;
}

.nav-notification-filters .checkbox {
    display: inline-block;
    color: #333;
}

.nav-notification-filters .lbl {
    font-size: 12px;
}

.nav-notification-filters {
    margin: 4px;
}

.monitor .mon-icon,
.generic .msg,
.book-msg {
    display: inline;
    width: 35px;
    margin: 4px;
}

.book-msg {
    margin-left: 0;
    margin-right: 8px;
}

.monitor .mon-type .label .icon {
    margin-right: 2px;
}

.monitor.with-error .mon-type .label,
.monitor.with-success .mon-type .label {
    background: transparent;
    color: #333;
    font-size: 12px;
    font-weight: bold;
    text-shadow: none;
}

.monitor .mon-msg {
    font-size: 11px;
    font-weight: normal;
    display: block;
    padding-top: 5px;
}

.monitor .mon-msg > div:first-child:before {
    display: block;
    content: ' '
}

.monitor .mon-msg > div:first-child {
    display: inline;
}

.monitor .mon-msg > div {
    display: inline-block;
}

.mon-msg > div > a {
    background: #333;
    color: #fff;
    padding: 2px 8px;
    border-radius: 4px;
}

.mon-msg > div > a:focus,
.mon-msg > div > a:hover {
    background: #666;
}

.mon-msg > div {
    margin-right: 8px;
}

.mon-msg > div:last-child {
    margin-right: 0;
}

.monitor .mon-type {
    font-size: 11px;
    font-weight: bold;
    margin-right: 5px;
}

.mention-msg {
    display: inline;
    width: 35px;
}

.mention-msg-info {
    display: inline-flex;
    align-items: center;
    width: 100%;
}

.mention-msg-info .mention-msg {
    display: block;
    margin-right: 5px;
}

.generic .avatar-container,
.mention-msg .avatar-container {
    width: 32px;
    height: 32px;
    float: left;
    margin-right: 10px;
    position: relative;
    border-radius: 100%;
    overflow: hidden;
}

.mention-msg .avatar-container .avatar-component {
    width: 40px;
    height: 40px;
}

.book-text,
.generic .text,
.mention-text {
    font-size: 13px;
    font-weight: 500;
    margin-right: 5px;
    line-height: 17px;
    letter-spacing: .4px;
}

.book-descr,
.generic .descr,
.mention-descr {
    font-size: 11px;
    line-height: 16px;
    padding-top: 4px;
}

.notification-footer {
    padding: 10px 20px 10px 14px;
}

.notification-footer .date {
    font-size: 11px;
    position: absolute;
    margin-top: -1px;
}

.notification-footer .details {
    width: 10px;
    margin: 0 2px 0 5px;
    padding: 0 !important;
    padding-bottom: 1px !important;
    padding-top: 1px !important;
    font-size: 15px;
    color: #555555 !important;
}

.notification-footer .btn-new {
    border: 0;
    height: 0;
    background: transparent;
}

.notification-footer .readed .btn-link {
    margin: 0 0 0 5px !important;
    padding: 0 !important;
}

.notification-footer .readed .icon-envelope-alt,
.notification-footer .readed .btn-link .icon-envelope {
    font-size: 15px;
    vertical-align: middle;
    color: #ffb752;
    margin-right: 0;
}

.notification-footer .readed {
    padding-top: 1px;
    padding-bottom: 1px;
}

.notification-footer .icon-time {
    margin-right: 0 !important;
    font-size: 11px;
}

.navbar .btn.btn-read-all,
.navbar .btn.btn-read-all:active {
    position: absolute;
    right: 5px;
    top: 5px;
    left: initial;
    margin: 0;
}

.notification-floating-alert > div > div {
    display: block;
    width: 350px;
    font-size: 13px;
    color: #555555;
}

.notification-floating-alert > div {
    padding: 0 !important;
}

.notification-floating-alert .close {
    background: transparent !important;
    border: none;
}

.notification-floating-alert .message {
    margin-left: 4px;
}

.notification-floating-alert {
    position: absolute;
    z-index: 400;
    right: 192px;
    top: 50px;
    display: none;
}

.notification-floating-alert-cockpit-enable > div > div {
    display: block;
    width: 350px;
    font-size: 13px;
    color: #555555;
    margin-top: 20px;
    right: -190px !important;
}

.notification-floating-alert-cockpit-enable > div {
    padding: 0 !important;
}

.notification-floating-alert-cockpit-enable .close {
    background: transparent !important;
    border: none;
}

.notification-floating-alert-cockpit-enable .message {
    margin-left: 4px;
}

.notification-floating-alert-cockpit-enable {
    position: absolute;
    z-index: 100;
    right: 212px;
    top: 32px;
    display: none;
}

.notificationsTable-container {
    max-height: 350px;
    max-height: calc(75vh - 100px);
}


.notificationsTable-container-dialog-list {
    max-height: 450px;
    height: 450px;
    margin-top: 10px;

}

.notification-title-icon {
    float: left;
    font-size: 20px;
}

.notification-title-description {
    padding-left: 26px;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.4px;
}

.notification-msg {
    margin-top: 6px;
    font-size: 11px;
    line-height: 18px;
}

.GenericMessage .mention-msg {
    vertical-align: -webkit-baseline-middle;
    vertical-align: -moz-middle-with-baseline;
    margin-right: 6px;
    font-size: 20px;
}

.GenericMessage .text {
    vertical-align: top;
    letter-spacing: unset;
}

li.no-hover.no-padding.nav-notification-filters {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 10px;
    margin: 0;
    display: flex;
    justify-content: space-around;
}

li.no-hover.no-padding.nav-notification-filters .checkbox label {
    margin-bottom: 0;
    padding: 5px 0;
}

.NotificationMenuButton li.no-hover.no-padding {
    background: #f7f8fa;
}

.notification-list-content .generic .avatar-container, .mention-msg .avatar-container {
    border: 0;
    width: 32px;
    height: 32px;
}

.notification-list-content i.icon.icon-bim-book {
    vertical-align: text-bottom;
}

.notification-list-content .mention-msg .avatar-container .avatar-component {
    width: 32px;
    height: 32px;
}

.nav-notification-filters .lbl {
    font-size: 12px !important;
    font-weight: 500 !important;
    letter-spacing: .5px;
}

li.nav-bar-button.NotificationMenuButton {
    padding: 0px 12px 0px 4px;
}

.NotificationMenuButton.dropdown-menu > li.nav-header .btn {
    width: 150px;
}

.scrollbar-inner.notification-list-content {
    width: auto !important;
}
