.userNameWrapper {
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.userNameWrapper :global(.BngAvatar) {
  height: 40px;
  width: 40px;
}

.userName {
  padding: 0 10px;
}

.fileName {
  border-radius: 9px;
  padding: 1px 11px;
  color: #ffffff;
}

.active {
  background: #29c46c;
}

.pending {
  background: #ffcf3c;
}

.guest {
  background: #ff913c;
}

.disabled {
  background: #e24c38;
}

.roleWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  width: fit-content;
  padding: 9px 15px;
}

.clickable {
  cursor: pointer;
}

.notClickable {
  cursor: not-allowed;
}

.clickable:hover {
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.actionsWrapper {
  display: flex;
  flex-direction: row;
}

.buttonsContainer {
  transition: max-height 500ms linear, opacity 300ms linear;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
}

.buttonsContainer :global(.BngIconButton) {
  padding: 12px;
}

.showButtons {
  max-height: 100vh;
  opacity: 1;
}

.buttonsContainer :global(label) {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  top: 10px;
  left: 60px;
  white-space: nowrap;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer !important;
}

.buttonsContainer :global(label:after) {
  position: absolute;
  top: 8px;
  left: -6px;
  content: '';
  width: 0;
  height: 0;

  border-right: solid 6px rgba(0, 0, 0, 0.8);
  border-bottom: solid 6px transparent;
  border-top: solid 6px transparent;
}

.buttonsContainer :global(div) {
  position: relative;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.usersPage :global(.search-button) {
  color: rgba(0, 0, 0, 0.54);
  margin-right: 0 !important;
  padding: 8px !important;
  transition: ease-in-out 250ms;
  transition-property: background-color, color;
}

.usersPage :global(.search-button:not(:disabled):hover) {
  background-color: rgba(0, 0, 0, 0.08);
}

.actionsDropdownPopper {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.195822);
  width: 300px;
  display: block;
  margin: 5px 0;
  border: 1px;
  border-radius: 4px;
  text-align: left;
  font-weight: 500 !important;
  max-width: 260px;
}

.actionsDropdownPopper :global(i) {
  font-size: 16px;
}

.actionsDropdownPopper :global(span) {
  font-size: 14px;
}

.actionButtonWrapper :global(div:not(#userLevelWrapper)) {
  display: flex;
  align-items: center;
  padding: 11px 15px;
  font-weight: 500;
  color: #6e6e6e;
  cursor: pointer;
  line-height: 30px;
}

.actionButtonWrapper :global(.BngDropdown) {
  padding: 0 !important;
}

.actionButtonWrapper :global(hr) {
  color: #e2e2e2;
  margin: 0;
  width: 100%;
}

.actionItem {
  max-height: 25px;
}

.actionButtonWrapper :global(div:hover:not(#userLevelWrapper)) {
  background: #efefef;
}

.actionButtonWrapper :global(i) {
  padding: 0 8px 0 5px;
}

.userLevelWrapper {
  transition: max-height 0.3s ease-in-out, top ease-out;
  max-height: 300px;
  overflow: hidden;
}

.userLevelWrapper.levelsHidden {
  max-height: 0;
}

.userLevelWrapper :global(div) {
  margin-left: 31px;
  border-left: 1px dashed #005dff;
}

.groupName {
  font-weight: 500;
  font-size: 12px;
}

.filterDropdownField :global(label) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.statusColor {
  padding: 7px;
  border-radius: 100%;
  border: 2px solid white;
  text-align: center;
}
