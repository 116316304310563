.dialog {
  left: 40vw;
  width: 50vw;
}

.dialogWrapper {
  padding: 16px 12px;
}

.header {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
}

.addButton {
  margin-left: auto;
}

.body {
  height: 65vh;
  overflow: hidden;
  padding: 8px 12px;
}

.fieldMdSize {
  width: 48%;
}

.groupFields {
  display: flex;
  gap: 4%;
}

.flexCtn {
  display: flex;
}

.flexCtn > :global(button) {
  margin-left: auto;
}

.ONGOING {
  background-color: #f89406;
}

.APPROVED {
  background-color: #00a355;
}

.REJECTED {
  background-color: #dc3545;
}

.tableActionPopper li i {
  font-size: 20px !important;
}

.tableActionPopper li span {
  font-size: 16px;
}

.adminTableContainer {
  height: 60vh;
}

.fieldsContainer {
  height: calc(65vh - 48px);
  overflow-y: auto;
  overflow-x: hidden;
}
