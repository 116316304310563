.user-menu-header-fragment.dropdown-menu .divider.first-divider {
    margin: 2px 1px;
}

.user-menu-header-fragment.dropdown-menu .help-link {
    margin-left: 5px;
}

.user-menu-header-fragment .menu-project-name {
    font-size: 12px;
    color: #999;
}

.user-menu-header-fragment .menu-user-email {
    font-size: 11px;
}

.user-menu-header-fragment .menu-user-display-name {
    font-size: 14px;
    color: #333;
    display: none;
}

.user-menu-header-fragment .menu-avatar-container {
    display: inline-block;
    vertical-align: top;
}

.user-menu-header-fragment .menu-avatar {
    width: 90px;
    height: 90px;
}

.user-menu-header-fragment.dropdown-menu .divider.second-divider {
    margin-bottom: 0;
}

.user-menu-header-fragment:not(.dropdown-menu) {
    margin-top: 8px;
}

.avatar-component.user-menu-avatar {
    width: 34px;
    height: 34px;
}

.avatar-component.menu-avatar {
    border-radius: 100%;
    border: 1px solid #ddd;
    margin: 10px;
    background-color: #ffffff !important;
}

.user-menu-info-display-name {
    color: rgba(0, 0, 0, 0.95);
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;
    word-wrap: break-word;
}

.user-menu-info-display-mail {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    display: -webkit-box;
    width: 100%;
    overflow: hidden;

}

.user-menu-info-display-project {
    color: #4A90E2;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.user-menu-info-container {
    padding: 14px;
    border-bottom: 1px solid #ededed;
}

.terms-or-policies-container {
    width: 215px;
    align-items: center;
    color: #7e7e7e;
    display: flex;
    font-size: 10px;
}

.terms-or-policies-container a {
    color: #7e7e7e;
}

.terms-or-policies-container a:hover {
    color: #4A90E2 !important;
    background-color: #f7f8fa !important;
}

.div-radius-user-menu {
    background-color: #7e7e7e;
    border-radius: 100%;
    width: 5px;
    height: 5px;
    margin: 0 3px;
}

.info-div.user-menu-info-container-data {
    padding: 20px 14px;
}

.user-menu-info-display-project {
    margin-top: 14px;
}

.user-menu-header-fragment.dropdown-menu {
    width: 380px;
}

.user-menu-info-container:hover {
    background: #f7f8fa;
}

.user-menu-avatar-outer {
    background: #ffffff;
    border-radius: 100%;
    width: 34px;
    height: 34px;
}

.user-menu-info-container .row-fluid .span8 .info-div.user-menu-info-container-data .terms-or-policies-container .policiesLink {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.user-menu-info-container .row-fluid .span8 .info-div.user-menu-info-container-data .terms-or-policies-container .termsLink {
    white-space: nowrap;
}

.MenuButtonDropdown .activateNowRoundBadge {
    background: #e6efff;
    border: 1px solid #cfe1ff;
    border-radius: 30px;
    color: #005dff;
    margin-left: 15px;
    padding: 5px;
}

.labs-release-label-container {
    margin: 13px 0 0 8px;
}