.BngCodeMirrorWrapper {
  height: 42vh;
}

.BngCodeMirrorWrapper :global(.BngCodeMirror) {
  height: 42vh;
}

.BngCodeMirrorWrapper :global(.BngCodeMirror .BngCodeMirrorEditor .CodeMirror) {
  height: 42vh;
}

.BngCodeMirrorWrapper :global(.BngCodeMirror .BngCodeMirrorEditor) {
  height: 40vh;
}
