.BngBigTable__ColumnConfigurationDialog {
    background: #efefef;
    height: calc(100% - 84px);
    position: fixed;
    z-index: 49;
    top: 82px;
    right: 53px;
    max-width: 322px;
}

.BngBigTable__ColumnConfigurationDialogForm {
    overflow-y: scroll;
    height: calc(100% - 45px);
}

.BngBigTable__ColumnConfigurationDialogHeader {
    background-color: #FFF;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BngBigTable__ColumnConfigurationDialogHeaderTitle {
    color: #005DFF;
    font-weight: bold;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.BngBigTable__ColumnConfigurationDialogHeaderName {
    color: #000;
    font-size: 15px;
}

.BngBigTable__ColumnConfigurationDialogHeaderBack {
    color: #005DFF;
    cursor: pointer;
}

.BngBigTable__ColumnConfigurationDialogHeaderLink {
    color: #005DFF;
    font-weight: bold;
}

.BngBigTable__ColumnConfigurationPinned .bng-button-group-item .material-icons {
    transform: rotate(90deg);
}

.BngBigTable__ColumnConfigurationDialog .AccordionDescription {
    flex: 1;
}

.BngBigTable__columnsForm__titleDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BngBigTable__columnsForm__titleDiv p {
    padding: 10px;
    margin-bottom: 0;
    font-weight: bold;
}
.BngBigTable__columnsForm__titleDiv span {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
}

.BngBigTable__columnsForm__help {
    font-size: 18px !important;
    cursor: pointer;
}

.BngBigTable__ColumnConfigurationDialog.BngBigTable__menuTab {
    width: 100% !important;
}

.BigTable__ImageUrlAccordionConfiguration {
    padding: 10px;
}

.BigTalbe__BorderConfiguration div span {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
}