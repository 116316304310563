.UserInfoPage .BngCardBody .FieldsTable th label {
  margin-bottom: 16px;
  margin-right: 8px;
  text-align: right;
  white-space: nowrap;
}

.UserInfoPage .PasswordInfo * {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #5f5f5f;
  opacity: 0.8;
}
