.DimensionTypeSelector {
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  padding: 6px;
  justify-content: space-between;
}

.DimensionTypeSelector,
.DimensionTypeSelector div,
.DimensionOption {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  color: #333333;
}

.BngDropdown {
  width: 24vh;
}

.BngDropdown:global(.disabled) {
  cursor: not-allowed;
  background-color: #d8d8d8;
}

.PopperOptions {
  box-shadow: 0 0 4px 0 #00000040;
  padding: 1px;
  border-radius: 4px;
  z-index: 99;
}

.DimensionOption {
  padding: 2px 8px 2px 8px;
  gap: 4px;
  background-color: #ffffff;
  border-top: 1px solid #e4e4e4;
  &:first-child {
    border-top: unset;
  }
}

.DimensionTypeSelector div i,
.DimensionOption i {
  color: #333333;
  margin-right: 5px;
}

.MaskOptionDropdown {
  width: 92%;
  padding: 2px 8px 2px 8px;
  gap: 4px;
  background-color: #ffffff;
  border-top: 1px solid #e4e4e4;
}

.PopperOptions:global(.Overlay) {
  z-index: 99;
  background-color: unset;
}

.MaskOptionDropdownOption {
  border: none;
  border-radius: unset;
  padding: unset;
}

.VisualizationName {
  width: 18vh;
}

.DimensionTypeSelector div {
  max-width: 19vh;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
