.pageWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.AccStructuresTab {
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  max-width: 1240px;
  overflow-y: scroll;
  padding: 0 32px 32px;
  position: relative;
  width: 100%;
}

.colEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userNameWrapper {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 285px;
}

.tableSection {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: fit-content;
  padding-bottom: 32px;
  width: 100%;
}

.sectionTitle {
  font-size: 16px;
  font-weight: 500;
}

.structuresTable {
  background: #f6f6f6;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  position: relative;
}

.structuresTable table {
  border-radius: 8px;
  overflow: hidden;
}

.structureIcon {
  color: #005dff;
  font-size: 24px;
  height: 24px;
  width: 24px;
}

.structuresTable table tr td,
.structuresTable table tr th {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

.structuresTable table tr td:first-child {
  padding-left: 20px;
}

.dataUpdateWrapper {
  align-items: center;
  display: flex;
}

.dataUpdate {
  align-items: center;
  cursor: help;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
}

.dataUpdateIcon {
  font-size: 21px;
}

.openProjectButton {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.structuresTable   {
  min-height: 250px;
}

.tableTitleWrapper {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  padding-top: 32px;
}

.structureTypeFilter :global(.flex-center-items) > div {
  height: 24px;
}
