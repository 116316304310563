.BngQuotaViewer {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 40px;
}

.valuePercent {
  color: #888888;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}

.progressBarWrapper {
  align-items: center;
  display: inline-flex;
  gap: 5px;
}

.QuotaViewerDropdown {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 5px;
  padding: 10px 15px 10px 15px;
  width: 280px;
}

.dropdownContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.infoRow {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  height: 24px;
}

.requestQuotaBtn {
  width: 100% !important;
}

.refreshBtn {
  margin-left: auto;
}
