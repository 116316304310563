.BngHorizontalCardBorder {
    border-radius: 5px;
    margin: 10px;
    overflow: hidden;
    transition: all .1s ease-in-out;
    box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.07);
}

.BngHorizontalCardBorder.premiumBorder {
    box-shadow: none;
    border-bottom: 1px solid #E4E4E8;
    border-right: 1px solid #E4E4E8;
    border-top: 1px solid #E4E4E8;
}

.BngHorizontalCardBorder.premiumBorder:hover {
    border-bottom: 1px solid #EAA319 !important;
    border-right: 1px solid #EAA319 !important;
    border-top: 1px solid #EAA319 !important;
}

.BngHorizontalCard {
    box-sizing: border-box;
    width: 375px;
    border: 1px solid #E4E4E8;
    border-radius: 4px;
    box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.07);
    display: inline-flex;
    cursor: pointer;
    flex-shrink: 0;
    white-space: normal;
    background-color: white;
    min-height: 115px;
}

.BngHorizontalCard.Disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

/* Icon */
.BngHorizontalCard .IconContainer {
    padding: 30px 25px;
    border-right: 1px solid #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BngHorizontalCard .IconContainer.withImage {
    width: 120px;
    padding: 15px 10px;
}

/* Image */
.BngHorizontalCard img {
    height: 90px;
    object-fit: scale-down;
    display: flex;
    align-items: center;
}

.BngHorizontalCard .IconContainer .Icon {
    font-size: 42px;
    opacity: 0.38 !important;
}

.BngHorizontalCard[data-test=BIG_TABLE] .IconContainer {
    padding: 30px 16px;
}

.BngHorizontalCard[data-test=BIG_TABLE] .IconContainer .Icon {
    font-size: 60px;
}

/* Text content */

.BngHorizontalCard .Content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    overflow: hidden;
    padding: 12px 17px !important;
    width: 100%;
}

.BngHorizontalCard .LabelWrapper {
    width: fit-content;
    align-self: flex-end;
}

.BngHorizontalCard .Content .Title {
    color: #202020;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.BngHorizontalCard .Content .Title .BngIsNewLabel {
    margin-left: 10px;
    align-self: flex-start;
    margin-top: 3px;
}

.BngHorizontalCard .Content .Title .BngIsBetaLabel {
    margin-left: 10px;
    align-self: flex-start;
    margin-top: 3px;
}

.BngHorizontalCard .Content .Text {
    margin-top: 5px;
    color: #444444;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
}

/* isPremium */
.BngHorizontalCard.isPremium {
    background-color: #F6F6F6;
    border-image: linear-gradient(180deg, #D77419 0%, #F6BF1A 68.2%) 1 100%;
    border-right: 0 !important;
    color: #888888;
    margin-left: 1px;
}

.BngHorizontalCard.isPremium:hover {
    background: rgba(246, 191, 26, 0.1) !important;
    border-right: 0 !important;
}

.BngHorizontalCard.isPremium .Title{
    color: #888888 !important;
}

.BngHorizontalCard.isPremium img {
    opacity: 50%;
}

/* BngIsNewLabel */
.BngIsNewLabel {
    box-sizing: border-box;
    border: 1px solid #269C59;
    border-radius: 8px;
    display: inline-block;
    padding: 1px 4px;
    color: #269C59;
    font-size: 9px;
    font-weight: 600;
    line-height: 10px;
    text-align: center;
    letter-spacing: 0.4px;
    white-space: nowrap;
}

/* BngIsBetaLabel */
.BngIsBetaLabel {
    box-sizing: border-box;
    border: 1px solid #d15b47;
    border-radius: 8px;
    display: inline-block;
    padding: 1px 4px;
    color: #d15b47;
    font-size: 9px;
    font-weight: 600;
    line-height: 10px;
    text-align: center;
    letter-spacing: 0.4px;
    white-space: nowrap;
}

.BngIsNewLabel.Blue {
    border: 1px solid #2a7dca;
    color: #ffffff;
    background-color: #2a7dca;
}

.BngIsComingLabel {
    margin: 8px 0;
    padding: 4px 8px;
    color: #FFFDFD;
    font-size: 9px;
    font-weight: 600;
    line-height: 10px;
    text-align: center;
    letter-spacing: 0.4px;
    white-space: nowrap;
    float: right;
    text-shadow: none;
    background: #0DA239;
    border-radius: 3px;
    line-height: 14px;
}

.BngHorizontalCard.isComing:hover {
    cursor: not-allowed !important;
}

/* BngIsUpgradeLabel */

.BngIsPremiumLabel {
    align-items: center;
    background: linear-gradient(94.97deg, #D77419 0%, #F6BF1A 68.2%);
    border-radius: 3px;
    color: #FFFDFD;
    display: flex;
    float: right;
    font-size: 9px;
    font-weight: 600;
    gap: 3px;
    letter-spacing: 0.4px;
    line-height: 14px;
    margin: 8px 0;
    overflow: hidden;
    padding: 6px 8px;
    position: relative;
    text-align: center;
    text-shadow: none;
    white-space: nowrap;
}
.BngIsPremiumLabel i {
    font-size: 14px;
}

.BngIsPremiumLabel:before {
    animation: upgradeShine 3s linear infinite;
    background-color: #fff;
    content: '';
    filter: blur(3px);
    height: 100px;
    left: -50%;
    opacity: .2;
    position: absolute;
    transform: rotate(45deg);
    transition: all ease 1s;
    width: 20px;
}

@keyframes upgradeShine {
    0% {
        left: -50%;
    }
    20% {
        left: 130%;
    }
    100% {
        left: 130%;
    }
}