.search-menu-button-container-enable{
    display: none;
}

input.search-menu-button-input {
    width: 190px;
    border: none;
    margin-top: -7px;
}

.search-menu-button-container {
    position: absolute;
    top: 10px;
    right: 90px;
    background: #fff;
    height: 20px;
}

.search-menu-button-container i.search-menu-button-icon.material-icons,
.search-menu-button-container i.search-menu-button-icon-close.material-icons{
    cursor: pointer;
}

.MenuButtonDropdown .dropdown-menu {
    display: block;
    position: relative;
    float: none;
    border: none !important;
}

.MenuButtonDropdown .tippy-content {
    padding: 0;
}