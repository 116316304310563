.TreeDropdownPopper {
  z-index: 321053 !important;
}

.BngTreeDropdown {
  font-size: 12px;
}

.BngTreeDropdown.disabled .FieldPreview {
  background: #eeeeee !important;
}

.FieldPreview {
  align-items: center;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 4px;
  display: flex;
}

:global(.form-group.has-error) .FieldPreview {
  border-color: #a94442;
}

:global(.form-group.has-error) .FieldPreview,
:global(.form-group.has-error) .Icon {
  color: #a94442;
}

.expandIcon {
  position: relative;
  right: 5px;
}

.breadcrumbWrapper {
  color: #555;
  padding: 9px 16px;
}

.BngTreePopper {
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  max-height: 350px;
  overflow: scroll;
}

.searchFieldWrapper {
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px 4px 0px 0px;
  color: #999999;
}

.searchFieldWrapper :global(.bng-search-input) {
  margin-left: 10px;
}

.TreeNode {
  cursor: pointer;
}

.TreeNode:hover {
  background: #dfe9fa;
}

.borderBottom {
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  margin: 0 14px;
}

.expandedNodeIcon {
  color: #3558ef !important;
}

.nodeLabelWrapper {
  align-items: center;
  display: flex;
}

.nodeLabelWrapper :global(i) {
  color: #666666;
  font-size: 20px;
  padding: 5px;
}

.nodeLabel {
  margin-left: 5px;
  white-space: nowrap;
}

.inlineItems {
  align-items: center;
  display: flex;
  height: 41px;
  max-height: 41px;
}

.childBorder {
  border-left: 1px dashed #cccccc;
}

.previewIcon {
  color: #3558ef;
}

.backgroundOverlay {
  z-index: 321052 !important;
}

.disabled {
  background-color: #f2f2f2;
  color: #a1a1a1;
  cursor: not-allowed;
}

.disabledIcon {
  color: #a1a1a1;
}


:global(.control-group.error) .FieldPreview {
  border-color: #f09784;
}

:global(.control-group.error) .FieldPreview,
:global(.control-group.error) .FieldPreview :global(.Icon),
:global(.control-group.error) .breadcrumbWrapper {
  color: #d68273 !important;
}