.quantitySelector {
  display: inline-flex;
}

.quantitySelectButton,
.quantityCounter {
  align-items: center;
  background: #fff;
  border: 1px solid #e4e4e4;
  display: flex;
  justify-content: center;
}

.quantitySelectButton {
  cursor: pointer;
  height: 28px;
  user-select: none;
  width: 26px;
}

.quantitySelectButtonLeft {
  border-radius: 4px 0 0 4px;
  border-right: none;
}

.quantitySelectButtonRight {
  border-radius: 0 4px 4px 0;
  border-left: none;
}

.quantitySelectButton.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.quantityCounter {
  height: 28px;
  width: 32px;
}
