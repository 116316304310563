.ConnectionsTabAccordionWrapper {
}

.ConnectionSelector {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  padding: 15px;
  border-radius: 5px;
  border-left: 4px solid #57a3ff;
}

.ConnectionSelector:global(.opened-true) {
  border-radius: 5px 5px 0 0;
}

.ConnectionsOptionsDropdown {
  margin-top: 2px;
  border-radius: 0 0 0 0;
}

.ConnectionListOption {
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 15px;
  border-left: 4px solid #57a3ff;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
}

.ConnectionListOption:hover {
  cursor: pointer;
}

.ConnectionListOption img {
  height: 30px;
  margin-right: 10px;
  mask-image: linear-gradient(to bottom, #57a3ff00 -50%, #57a3ff 100%);
}

.ConnectionListOption i {
  font-size: 30px;
  margin-right: 10px;
  color: #005dff;
}

.ConnectionListOption:global(.new-database-connection) {
  border-radius: 0 0 5px 5px;
}

.ConnectionListOptionPopper:global(.BngClickOutsideOverlay.Overlay) {
  z-index: 99;
  background: rgba(0, 0, 0, 0) !important;
}

.ConnectionListOptionPopper:global(.bng-dropdown-parent) {
  z-index: 99;
  width: 299px;
  left: 1px !important;
}

.ConnectionSelectorButton {
  width: 100%;
}

.ConnectionSelectorWrapper > i {
  position: absolute;
  top: 20px;
  right: 40px;
  font-size: 15px;
}

.ConnectionButton {
  padding: 5px;
  position: absolute;
  top: 115px;
  right: 20px;
}

.schemasWrapper {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
  gap: 1px;
  display: flex;
  flex-direction: column;
}

.schemaName {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  gap: 8px;
  padding: 8px 0 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.schemaNameArrowIcon {
  color: #888888;
  font-size: 20px !important;
}

.schemaNameArrowIcon:global(.cerrado) {
  transform: rotate(0deg);
  transition: all 0.75s 0.15s;
}

.schemaNameArrowIcon:global(.open) {
  transform: rotate(90deg);
  transition: all 0.75s 0.15s;
}

.schemaNameArrowIcon:global(.hide) {
  filter: opacity(0);
}

.listWrapper {
  position: relative;
}

.subListWrapper {
  margin-left: 20px;
}

.subListWrapper:global(.closed) {
  transition: all 0.75s 1s ease-out;
  transform-origin: top;
  display: none;
  transform: scaleY(0);
}

.subListWrapper:global(.open) {
  transition: all 0.75s 1s ease-out;
  transform-origin: top;
  display: flow;
  transform: scaleY(1);
}

.listItemIcon {
  color: #888888;
  font-size: 20px !important;
}

.itemAddIcon {
  position: absolute;
  right: 0;
  top: 10px;
  color: #888888;
  font-size: 20px !important;
}

.itemName {
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemName:global(.column) {
  width: unset;
}

.keyIcon {
  font-size: 20px !important;
  transform: rotate(90deg);
}

.keyIcon:global(.PRIMARY) {
  color: #c9c90e;
}

.keyIcon:global(.FOREIGN) {
  color: red;
}

.NextStepButton {
  position: absolute;
  bottom: 1vh;
  right: 1vh;
  width: 150px;
}
