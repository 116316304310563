/* Styles for Chromium-based browsers, is is needed so it works the same way as in firefox */
@supports (-webkit-appearance: none) {
  .tutorialWrapper :global(span) {
    letter-spacing: -0.01em;
  }
}

.tutorialWrapper {
  border-radius: 8px;
  height: 80vh;
  width: 500px;
}

.tutorialWrapper :global(.modal-body) {
  border-radius: 8px;
}

.tutorialWrapper :global(.widget-body) {
  border: none;
  padding-bottom: 0;
}

.tutorialWrapper :global(.widget-main) {
  border: none;
  padding-bottom: 0;
}

.tutorialWrapper :global(.dialog-body) {
  overflow: hidden;
}

.animationWrapper {
  will-change: transform, opacity;
  width: 100%;
  height: calc(80vh - 134px);
}

.animationWrapper.withTitle {
  height: calc(80vh - 155px);
}

.stepContainer {
  overflow-y: auto;
  position: absolute;
  width: 100%;
  height: 100%;
}

.paginateContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  margin: 24px 0;
}

.dotContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dotPagination {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--gray-default);
}

.dotPosition {
  position: absolute;
  border-radius: 50%;
  background-color: var(--blue-default);
}

.dotPaginationContainer {
  display: flex;
  gap: 6px;
}

.tutorialWrapper .paginateContainer :global(i) {
  color: var(--dark-gray-title);
  font-size: 32px;
}

.arrowNotAllowed {
  opacity: 0.5;
  cursor: not-allowed;
}

.paginateContainer .paginateForwardArrow.arrowNotAllowed:hover,
.paginateContainer .paginateForwardArrow.arrowNotAllowed:active {
  background-color: transparent;
}

.arrowDisabled {
  opacity: 0;
  cursor: auto;
}

.paginateBackArrow {
  margin-right: auto;
}

.paginateForwardArrow {
  margin-left: auto;
}

.footerComponent {
  position: absolute;
  height: 48px;
  width: 500px;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 48px;
  pointer-events: none;
}

.footerComponent > div {
  pointer-events: all;
}
