.search-item-result {
    font-size: 13px;
    text-align: left;
}

.search-item-result .search-item-folder {
    display: flex;
    align-items: center;
}

.search-item-name .search-item-icon i,
.search-item-result .search-item-folder i {
    font-size: 20px;
}

.search-item-result .search-item-folder i {
    margin-right: 6px;
}

.search-item-name {
    display: flex;
    align-items: center;
    background: #EBEFF8;
}

.search-item-folder, .search-item-name {
    padding-left: 20px;
    color: #7B7B7C;
}

.search-item-folder i {
    font-weight: normal;
}

.search-item-name span, .search-item-folder {
    letter-spacing: .5px;
    font-weight: 500;
    font-size: 12px;
}

.typeahead.dropdown-menu {
    max-height: 400px;
    max-height: 40vh;
    overflow: auto;
}

ul.typeahead.dropdown-menu {
    width: 600px;
    border: 0;
    display: block;
    padding: 0;
    margin: 0;
    border-radius: 4px !important;
    top: 83px !important;
    position: fixed;
    background: #f7f8fa;
}

ul.typeahead.dropdown-menu li a {
    padding: 0;
    line-height: 36px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    margin: 0;
}

.search-item-folder {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

ul.typeahead.dropdown-menu li a:hover, ul.typeahead.dropdown-menu li a:focus, ul.typeahead.dropdown-menu li a:active,
ul.typeahead.dropdown-menu li.active a, ul.typeahead.dropdown-menu li.active a:hover {
    background: #ffffff;
    color: #7B7B7C !important;
}

ul.typeahead.dropdown-menu li a:hover, ul.typeahead.dropdown-menu li a:focus, ul.typeahead.dropdown-menu li a:active,
ul.typeahead.dropdown-menu li.active a, ul.typeahead.dropdown-menu li.active a:hover {
    background: #f7f8fa;
}

.search-item-name:hover, .search-item-folder:hover {
    background: #FFFFFF;
    color: #7B7B7C !important;
}

.SearchResultDiv {
    width: 572px;
    border-bottom: 0;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .5px;
    padding: 8px 14px;
    position: absolute;
    background: #fff;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 99;
    text-align: left;
}

.nav-search-input-new-line {
    height: 2px;
    background: #fff;
    margin-top: -10px;
    margin-bottom: 8px;
    position: relative;
    width: calc(100% - 10px);
    opacity: 1;
    animation: lazy-grow 0.6s ease ;
}

@keyframes lazy-grow {
    0% {
        opacity: 0;
        width: 0;
    }
    100% {
        opacity: 1;
        width: calc(100% - 10px);
    }
}

.nav-search-button,
.nav-search-input-box {
    margin-right: 10px;
}

.nav-search-button-on-input-right-box {
    width: 110px;
    position: absolute;
    top: 14px;
    color: #fff;
    right: 0;
}

.nav-search-input-box {
    overflow: hidden;
    height: 48px;
    opacity: 1;
    transition: opacity .6s ease, top .6s ease;
}

.nav-search-new input[type="text"] {
    border-radius: 0;
    color: rgba(255, 255, 255, .75);
    background: transparent;
    border: 0 transparent;
    width: 100%;
    margin-top: 9px;
    height: 32px;
    padding: 0 30px;
}

.nav-search-new input[type="text"]::placeholder {
    color: rgba(255, 255, 255, .75);
}

.nav-search-new {
    position: relative;
}

.nav-search-new-item,
.nav-search-new {
    height: 50px;
}

.nav-search-new,
.nav-search-new .nav-search-button {
    cursor: pointer;
}

.nav-search-input-box,
.nav-search-button {
    top: 0;
    position: relative;
}

.nav-search-new.expanded {
    cursor: unset;
    display: inline-flex;
}

.ObjectSearch-search-enter {
    opacity: 0.01;
    transform: translateY(25%);
}

.ObjectSearch-search-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 500ms ease;
}

.ObjectSearch-search-exit {
    opacity: 1;
    transform: translateY(0%);
}

.ObjectSearch-search-exit-active {
    opacity: 0.01;
    transform: translateY(25%);
    transition: all 500ms ease;
}

.hidden-bottom {
    top: 39px;
    right: 100%;
    opacity: 0;
    z-index: -1;
    position: absolute;
    animation: .6s lazy-hide;
}

@keyframes lazy-hide {
    0% {
        z-index: 0;
    }

    99% {
        z-index: 0;
    }
    100% {
        z-index: -1;
    }
}

