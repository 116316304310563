/*Object Right Menu Accordion*/
.main-content .ObjectRightMenuAccordion,
#right-menu-container .ObjectRightMenuAccordion {
    top: 84px;
    height: calc(100% - 84px);
}

.ObjectRightMenuAccordion {
    position: fixed;
    z-index: 49;
    top: 0;
    right: 53px;
    height: 100%;
    min-width: 340px;
    width: 340px;
    border-radius: 0 !important;
    box-shadow: -1px 0 0 0 #e2e2e2;
    overflow: auto;
}

.ObjectRightMenuAccordion .AccordionContent {
    max-height: calc(100% - 150px);
}

.ObjectRightMenuAccordion .AccordionSideMenu {
    height: unset;
}

.ObjectRightMenuAccordion .FolderContainer .button-create-folder {
    width: unset;
    padding: 0 3px;
    display: flex;
    align-items: center;
}

.ObjectRightMenuAccordion .FolderContainer .button-create-folder .Icon {
    font-size: 20px;
}

/*End Object Right Menu Accordion*/

.AccordionWrapper {
    background-color: #efefef;
}

.AccordionWrapper .AccordionSideMenu:last-child {
    border-radius: 0 0 4px 4px;
    box-shadow: 0 -1px 0px 0px #e2e2e2;
}

.AccordionWrapper .AccordionSideMenu:only-child {
    border-radius: 4px;
}

.AccordionSideMenu {
    background-color: #efefef;
    height: 100%;
    border: 1px solid transparent;
    box-shadow: 0 0 1px 1px #e2e2e2;
}

.AccordionDescription {
    font-size: 13px;
    font-weight: 500;
}

.AccordionTitle {
    display: flex;
    align-items: center;
    padding: 4px 11px;
    cursor: pointer;
}

.AccordionTitle.AccordionCustomTitle .BngSwitch {
    margin-left: auto;
}

.AccordionSideMenu.AccordionOpen .AccordionTitle {
    box-shadow: 0 1px 0 0 #e2e2e2;
}

.AccordionTitle > * {
    margin-right: 10px;
}

.AccordionTitle > *:last-child {
    margin-right: 0;
}

.AccordionContent {
    padding: 20px;
    overflow: auto;
}

.AccordionSideMenu.AccordionClose .AccordionContent {
    max-height: 0;
    padding: 0 11px;
}

.AccordionIcon {
    transition: transform 0.2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.AccordionSideMenu.AccordionClose .AccordionIcon {
    transform: rotate(90deg);
}

.AccordionSideMenu.AccordionOpen .AccordionIcon {
    transform: rotate(180deg);
}

.AccordionSideMenu.AccordionDisabled {
    color: #888;
}

.AccordionWrapper .UiBlocker {
    height: 100%;
}

.AccordionFixedButton {
    position: fixed;
    bottom: 0;
    height: 55px;
    border-radius: 0;
    z-index: 50;
}

.AccordionFixedButton .AccordionContent {
    padding: 10px;
}

.AccordionFixedButton .AccordionContent button.bng-button {
    width: 100%;
    margin: 0;
}

#ObjectRightMenuAccordionWrapper .AccordionFixedButton {
    right: 51px;
    min-width: 340px;
}

#ObjectRightMenuAccordionWrapper .ObjectRightMenuAccordion.HasFixedButton {
    height: calc(100% - 142px);
}

.ObjectRightMenuAccordion.withAction .AccordionChildren {
    overflow-y: auto;
}

.ObjectRightMenuAccordion.withAction .UiBlocker {
    display: flex;
    flex-direction: column;
}

.AccordionWrapper .ActionButtonContainer {
    margin-top: auto;
    box-shadow: 0 0 1px 1px #e2e2e2;
}

.AccordionSideMenu.AccordionOpen.sticky {
    position: sticky;
    top: 0;
    z-index: 1;
}

.AccordionSideMenu.containError .AccordionIcon,
.AccordionSideMenu.containError .AccordionDescription {
    color: #b94a48;
}
