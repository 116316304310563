.map-editor .tab-content {
    height: calc(100vh - 161px);
    overflow: hidden;
    padding: 16px 3px 16px 12px;
}

.map-editor .tab-content .conf-container {
    height: calc(100vh - 206px);
    margin-bottom: 8px;
    overflow: auto;
}

.map-editor .tab-content .conf-container .table {
    margin: 0;
}

.map-editor .map-preview-container .tab-content {
    padding: 0;
    height: calc(100vh - 140px);
}

.map-editor .selected {
    border: 1px solid #EEE;
}

.map-preview {
    height: calc(100vh - 140px);
    position: relative;
}

.map-editor .tab-buttons .btn {
    padding: 2px 12px;
    margin: 0px;
}

.map-editor .tab-buttons{
    height: 28px;
    margin-top: 15px;
}

.tab-buttons .btn.small {
    padding: 1px 0 0 2px;
    font-size: 12px !important;
}

.button-container > button > i {
    font-size: 16px;
    padding: 2px;
    margin: 0 !important;
}

.map-editor .add-tooltip-btn {
    height: 30px;
}

.map-editor td.text-center {
    text-align: center;
}

.map-editor .unstyled > li > b {
    width: 70px;
    display: inline-block;
}

.map-circles-ic {
    display: inline-block;
}

.map-circles-ic .color-container .color {
    padding: 0;
    border: 0;
    height: 10px;
    width: 10px;
    cursor: pointer;
}

.map-circles-ic .color {
    border-radius: 100%;
}

.legend-item-config {
    margin-bottom: 5px;
}

.legend-item-config:last-child {
    margin-bottom: 0;
}

.measure-types > label {
    display: inline-block;
    margin-right: 10px
}

.measure-types > label:last-child {
    margin-right: 0;
}

input.borderless-input,
select.borderless-input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    height: 29px;
}

.bound-config .borderless {
    border-radius: 5px;
    border-right: 1px solid;
    border-left: 1px solid;
}

.bound-config .borderless-input {
    padding-right:0;
    width: 40px;
}

.borderless .add-on {
    background: transparent;
    border: none;
}

.borderless .add-on:last-child {
    padding-left: 0;
}

.borderless input {
    padding-right: 0;
    padding-left: 0;
    text-align: right;
}

.operator-selection {
    width: 120px;
    padding-right: 0;
}

.band-value-input {
    width: 80px;
    padding-right: 0;
    text-align: right;
}

.add-band-button-div {
    border-bottom: 1px solid #CCC;
    padding-bottom: 5px;
    margin-bottom: 5px;
    text-align: right;
}

.map-editor .map-editor-options legend {
    font-size: 13px;
}

.map-editor .map-editor-options * {
    font-size: 12px;
}

.btn-editor-map-enable-true.enable{
    background-color: #2283c5 !important;
    color: #fff !important;
}

.btn-editor-map-enable-false.enable{
    background-color: #e7e7e7 !important;
    color: #888888 !important;
}



/*

.map-editor .legend-container {
    max-height: 400px;
    overflow: auto;
}


.map-editor .tooltips-container {
    max-height: calc(30vh - 40px);
    overflow: auto;
}*/

.map-editor .add {
    vertical-align: super;
}

.map-editor .floating-object-actions .on-react{
    margin-top: -26px !important;
}

.map-editor .fill-w.with-add {
    width: calc(100% - 25px);
}
.map-editor .overlay {
    background-color: white;
    opacity: 0.2;
}
.map-editor .expanded-analyst-menu {
    width: 50vw;
    z-index: 12;
}

.map-editor .analyst-expand-btn {
    border-radius: 0;
    position: absolute;
    right: 4px;
    top: 3px;
    color: #C0C0C0 !important;
    padding: 0 !important;
}

.map-editor .analyst-expand-btn:active,
.map-editor .analyst-expand-btn:focus,
.map-editor .analyst-expand-btn:hover {
    text-decoration: none;
    outline: none;
}

.sweet-alert p > pre.MapEditorMdx {
    height: 150px;
    overflow: auto !important;
    white-space: nowrap;
}

.showSubtitleOnMapButton {
    height: 20px;
    padding-top: 0 !important;
}

.showSubtitleOnMapButton > i {
    font-size: 20px !important;
    padding: 0 !important;
}