.usermobile-table-tr:nth-child(even) {
    background-color: #f6f6f6 !important;
}

.usermobile-table-tr:nth-child(odd) {
    background-color: #ffffff !important;
}

.table-title-column {
    padding-left: 14px;
    height: 37px;
    line-height: 37px;
}

.NoFormCheckbox.checkbox.enableuser-mobile-checkbox > label > input[type=checkbox] {
    width: 16px !important;
    height: 16px !important;
    margin-top: 0;
}

.NoFormCheckbox.checkbox.enableuser-mobile-checkbox > label > span.lbl::before {
    margin: 0px;
}