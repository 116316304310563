.tabHeader {
  background: #ffffff;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  height: 30px;
  padding: 15px 25px;
}

.leftButton {
  border-radius: 6px;
  color: #005dff;
  cursor: pointer;
  display: inline-flex;
  font-weight: 700;
  font-size: 16px;
  padding: 3px;
}

.leftButton:hover {
  background-color: #eee;
}

.headerActions {
  align-items: center;
  display: inline-flex;
}

.simpleAccTableWrapper {
  height: 100%;
  max-height: calc(100% - 61px);
  overflow-y: scroll;
}

.SimpleTable {
  background: #f6f6f6;
  border-bottom: 1px solid #e4e4e4;
  position: relative;
}

.SimpleTable :global(.BngEmpty) {
  transform: translateY(100%) !important;
}

.SimpleTable tr,
.SimpleTable th {
  color: #555555;
}

.SimpleTable :global(.BngTableTh) {
  padding: 10px 12px;
}

.SimpleTable :global(.BngTableTd) {
  padding: 2px 12px;
}

.pending {
  color: #f98900;
}

.accepted {
  color: #00a355;
}

.rejected,
.deleted,
.disabled {
  color: #e9443b;
}

.statusBall {
  padding: 7px;
  border-radius: 100%;
  border: 2px solid white;
  text-align: center;
}

.background-pending {
  background: #f98900;
}

.background-accepted {
  background: #00a355;
}

.background-rejected,
.background-deleted,
.background-disabled {
  background: #e9443b;
}

.buttonLabel {
  font-weight: 500;
  padding: 3px;
  margin: 0;
}

.disabledButton {
  cursor: not-allowed !important;
  opacity: 0.5;
}
