.BngAvatarUploadInput {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  position: relative;
  cursor: pointer;
}

.BngAvatarUploadInput .ImageContainer {
  width: 140px;
  height: 140px;
  border-radius: 100%;
  overflow: hidden;
}

.BngAvatarUploadInput .ImageContainer img {
  min-width: 100%;
  min-height: 100%;
}

.BngAvatarUploadInput input {
  display: none;
}

.BngAvatarUploadInput .Icon {
  font-size: 100px;
  color: #969696;
}

.BngAvatarUploadInput .InnerCircle {
  width: 120px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  position: absolute;
  transition: opacity ease-in-out 300ms;
  opacity: 0;
}

.BngAvatarUploadInput:hover .InnerCircle {
  opacity: 1;
}

.BngAvatarUploadInput .InnerCircle .Icon {
  color: white;
}

/*
.BngAvatarUploadInput .InnerCircle .EditIcon {
  font-size: 32px;
  position: absolute;
  top: 15px;
  right: 15px;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}*/
