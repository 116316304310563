.ProfileDialog .dialog-body {
    padding-top: 5px;
}

.ProfileDialog ul.navigation-tabs {
    padding: 0 5px;
}

/*profile tab*/
.ProfileDialog .mb-0 {
    margin-bottom: 0;
}

.ProfileDialog .profile-tab-personal-data {
    display: flex;
    height: 400px;
}

.ProfileDialog .profile-card {
    width: 35%;
    background: #fff;
    border: 1px solid #EFEFEF;
    border-radius: 4px;
    position: relative;
}

.ProfileDialog .profile-card.user {
    height: 400px;
}

.ProfileDialog .profile-user-status {
    position: relative;
    float: right;
    margin-top: -5px;
    margin-right: -5px;
    padding: 0 7px;
    border-radius: 2px;
    height: 18px;
    line-height: 18px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.4px;
    background-color: #575757;
    color: #fff;
}

.ProfileDialog .profile-user-status.online {
    background-color: #82AF6F;
}

.ProfileDialog .profile-user-status.offline {
    background-color: #ce6161;
}

.ProfileDialog .profile-carousel {
    position: relative;
    padding: 0 15px;
    margin: 30px auto 0;
    text-align: center;
}

.ProfileDialog .avatar-carousel-button {
    padding: 3px;
    width: 18px;
    height: 18px;
    background-color: #FFF;
    border: 1px solid #EBEBEB;
    border-radius: 100%;
    position: absolute;
    z-index: 2;
    bottom: calc(50% - 15px);
    cursor: pointer;
}

.ProfileDialog .avatar-carousel-button.left {
    left: 0;
}

.ProfileDialog .avatar-carousel-button.right {
    right: 0;
}

.ProfileDialog .avatar-carousel-button > i {
    line-height: 18px;
    font-size: 18px;
}

.ProfileDialog .profile-user-messaging .qtip-hint.help-icon-whatsapp {
    padding-right: 0;
    position: absolute;
    left: 219px;
}

.ProfileDialog .profile-user-messaging .messaging-icons .icon-whatsapp {
    font-size: 24px;
    padding-right: 3.43px;
}

.ProfileDialog .profile-image {
    position: relative;
    overflow: hidden;
    border-radius: 100%;
    border: 1px solid #ececec;
    background: #f6f6f6;
    z-index: 1;
    margin: auto;
}

.ProfileDialog .profile-carousel,
.ProfileDialog .profile-image,
.ProfileDialog .profile-image > img {
    width: 120px;
    height: 120px;
}

.ProfileDialog .profile-image .upload {
    background: rgba(0,0,0,0.7);
    color: #fff;
    font-size: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    cursor: pointer;
    pointer-events: none;
}

.ProfileDialog .profile-user-description {
    display: block;
    text-align: center;
    margin: 0 auto;
    padding: 10px;
}

.ProfileDialog .profile-user-description span {
    display: block;
    padding: 1px;
    color: #292C31;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 234px;
}

.ProfileDialog .profile-user-name {
    font-size: 16px;
    display: inline-flex !important;
    gap: 6px;
}

.ProfileDialog .profile-user-last-access {
    font-size: 10px;
    letter-spacing: 0.33px;
}

.ProfileDialog .profile-user-configuration {
    margin: 8px 0 0;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.ProfileDialog .profile-user-configuration > div {
    border-top: 1px solid #EFEFEF;
    height: 26px;
    padding: 10px 25px;
    font-size: 12px;
    color: #888;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.ProfileDialog .profile-user-configuration > div:hover {
    color: #005DFF;
    opacity: 1;
}

.ProfileDialog .profile-user-configuration > div > * {
    vertical-align: bottom;
    padding-right: 10px;
}

.ProfileDialog.CreateUserProfile .profile-form-data {
    padding-top: 0;
}

.ProfileDialog .profile-form-data {
    width: 70%;
    padding: 30px 24px 0 22px;
    overflow-y: auto;
}

.ProfileDialog .profile-form-data > * {
    padding: 3px 0;
}

.ProfileDialog .hidden-input-file {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 20px !important;
    opacity: 0;
    width: 100% !important;
}

/*end profile tab*/

/*permissions tab*/

.ProfileDialog .profile-user-permission {
    display: flex;
    height: 400px;
    padding: 0 10px;
}

.ProfileDialog .profile-permission-title {
    font-weight: 500;
    color: #000;
    margin-bottom: 20px;
    display: block;
}

.ProfileDialog .profile-user-groups {
    width: 35%;
    margin-right: 5%;
}

.ProfileDialog .profile-user-tree-table {
    width: 60%;
}

.ProfileDialog .user-group-card {
    word-break: break-word;
    color: #888;
    background: #fff;
    border: 1px solid #EFEFEF;
    padding: 8px 15px;
    min-height: 40px;
    display: flex;
    align-items: center;
}

.ProfileDialog .user-group-card > i {
    margin-top: -4px;
}

.ProfileDialog .user-group-card span {
    padding: 0 10px;
}

.ProfileDialog .user-group-card:first-child {
    border-radius: 4px 4px 0 0;
}

.ProfileDialog .user-group-card:last-child {
    border-radius: 0 0 4px 4px;
}

.ProfileDialog .without-permission-information {
    text-align: center;
    padding: 100px 50px;
    word-break: break-word;
}


/*end permissions*/

.BimachineBadge {
    width: 16px;
    height: 16px;
    background: #005DFF;
    border-radius: 100%;
    color: white;
}

.BimachineBadge > i,
.BimachineBadge > i::before {
    transform: scale(0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
}

.ProfileDialog .bng-button.save,
.ProfileDialog .bng-button.cancel {
    margin-top: 10px
}