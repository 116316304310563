.ActionList {
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    overflow-y: auto;
}

.ActionListItems .BngDraggableItem {
    box-shadow: inset 0 -1px 0 0 #E7E8EB;
}

.ActionList .BngIconButton .Icon {
    color: rgba(0, 0, 0, .75);
}

.ActionListItems {
    padding: 4px 10px;
    overflow: auto;
}

.ActionListItems .BngDraggableItem .ActionListItem {
    width: 100%;
}

.ActionListItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
    font-weight: 400;
    box-shadow: inset 0 -1px 0 0 #E7E8EB
}

.ActionListItem:first-child {
    padding-top: 0;
}

.ActionListItem:last-child {
    padding-bottom: 0;
    box-shadow: unset;
}

.ActionListItemDescription {
    width: 100%;
    word-break: break-word;
}

.ActionListSearch {
    position: relative;
    margin-top: 3px;
    padding: 4px;
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 0 0 #E7E8EB;
}

.ActionListSearch input {
    margin-bottom: 0;
    width: 100%;
    height: 100%;
    border: 0;
    font-size: 12px;
}

.ActionListSearch input::placeholder {
    color: #B5B5B5;
}

.ActionListSearch button {
    outline: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 100%;
    padding: 4px;
    color: #383838;
}

.ActionListSearch button:hover {
    background-color: #f6f6f6;
}

.ActionListItemActions {
    display: flex;
    align-items: center;
}

.ActionListItemActions .BngIconButton {
    margin-right: 6px;
    padding: 4px;
}

.ActionListItemActions .BngIconButton:last-child {
    margin-right: 0;
}

.ActionListItemActions .Icon {
    font-size: 16px;
}

.ActionListEmpty {
    padding: 6px 0;
    display: block;
    text-align: center;
}

.LastChildBorderless .ActionListItems .ActionListItem:last-child,
.LastChildBorderless .ActionListItems .BngDraggableItem:last-child,
.LastChildBorderless .ActionListItems .BngDraggableItem:last-child .ActionListItem {
    box-shadow: none;
}

.ActionTagItemHeader {
    padding-bottom: 5px;
    margin-top: 5px;
}

.ActionTagItemHeader .ActionTagItemDescription {
    width: 100%;
}

.ActionTagItemHeader .ActionTagItemDescription .Icon {
    font-size: 17px;
    margin-right: 5px;
}

.ActionList.ActionTagList {
    background-color: transparent;
}

.ActionTagList .ActionListItems {
    padding: 0;
}

.ActionTagList .ActionListSearch {
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
}

.ActionTagItem {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid transparent;
    padding: 4px 10px;
    margin-bottom: 10px;
}

.ActionTagItemActions .BngIconButton {
    padding: 6px;
}

.ActionTagItemActions .BngIconButton .Icon {
    font-size: 18px;
    color: #222;
}

.ActionListEmpty {
    background-color: #fff;
    border-radius: 4px;
    padding: 4px;
}

.ActionListEmpty .ActionListItemsEmpty-Wrapper {
    width: 70%;
    margin: auto;
    padding: 10px;
}

.ActionListEmpty .ActionListItemsEmpty-Wrapper > * {
    display: block;
    margin: 0 auto 8px;
    text-align: center;
}

.ActionListItemsEmpty-Wrapper .Icon {
    width: 24px;
    padding: 12px;
    color: #005dff;
    background: rgba(0, 93, 255, 0.1);
    border-radius: 100px;
}