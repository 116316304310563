.BngCard {
  border: 1px solid #efefef;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.BngCard .BngCardBody {
  padding: 20px 14px;
  flex-grow: 1;
}

.BngCard .BngCardHeader {
  justify-self: start;
}

.BngCard .BngCardHeader > h1 {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #292c31;
}

.BngCard .BngCardFooter {
  border-top: 1px solid #e2e2e2;
  padding-top: 20px;
  justify-self: end;
}

.BngCard.withShadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
