.dash-item-newmenu {
    position: absolute;
    z-index: 3;
    right: 12px;
    top: 12px;
    cursor: pointer;
    outline: 0;
}


/*not overflow map options*/
.grid-stack-item-content-newmap .dash-item-newmenu {
    right: 64px;
}

.bng-dropdown.container-dash-item-newmenu,
.dashboard-item-popper {
    width: 240px;
    z-index: 101;
}

.dashboard-item-popperOverlay {
    z-index: 100;
}

.dash-item-newmenu .DashboardItemMenuDropdown button {
    background: transparent;
    padding: 4px;
}

.BngDropdown.DashboardItemMenuDropdown {
    opacity: 0;
    transition: opacity ease-in-out 150ms;
}

.grid-stack-item-content:hover > .dash-item-newmenu .BngDropdown.DashboardItemMenuDropdown,
.BngDropdown.DashboardItemMenuDropdown:hover,
.BngDropdown.DashboardItemMenuDropdown:focus,
.BngDropdown.DashboardItemMenuDropdown.active {
    opacity: 1;
}

.drop-button.dash-item-newmenu-drop-button {
    border-radius: 100%;
}

.Item:hover .drop-button.dash-item-newmenu-drop-button {
    opacity: 1;
}

.bng-dropdown.container-dash-item-newmenu {
    cursor: default;
    padding: 4px 0;
    left: 0;
    position: absolute;
}

.bng-dropdown.container-dash-item-newmenu li {
    display: flex;
    align-items: center;
    padding: 0;
}

.bng-dropdown.container-dash-item-newmenu li:last-child,
.bng-dropdown.container-dash-item-newmenu .item:last-child {
    box-shadow: none;
}

.bng-dropdown.container-dash-item-newmenu li label {
    margin-bottom: 0;
}

ul.bng-dropdown.container-dash-item-newmenu li .Icon,
.bng-dropdown.container-dash-item-newmenu li .lbl::before {
    margin-right: 12px;
}

.bng-dropdown.container-dash-item-newmenu li .lbl::before {
    vertical-align: text-bottom;
}

.bng-dropdown.container-dash-item-newmenu li span.lbl {
    line-height: 16px;
}

.bng-dropdown.container-dash-item-newmenu li > div {
    width: 100%;
    position: relative;
    margin-right: 0;
}

.bng-dropdown.container-dash-item-newmenu .bt-item-new-menu-inside {
    padding: 0;
    position: relative;
}

.bng-dropdown.container-dash-item-newmenu li,
.bng-dropdown.container-dash-item-newmenu ul > li,
.bng-dropdown.container-dash-item-newmenu .bt-item-new-menu-inside .DashboardItemMenuTitle {
    padding: 12px 16px;
}

.bng-dropdown.container-dash-item-newmenu li,
.bng-dropdown.container-dash-item-newmenu li span {
    line-height: 13px;
    font-size: 13px;
    font-weight: 500;
    box-shadow: none;
}

ul.bng-dropdown.container-dash-item-newmenu ul a {
    color: #6e6e6e;
}

ul.bng-dropdown.container-dash-item-newmenu li .Icon {
    font-size: 14px;
    width: 16px;
    height: 16px;
}

ul.bng-dropdown.container-dash-item-newmenu li [class*="icon-bim-"]:before {
    vertical-align: text-top;
}

.disabledOption, .disabledOption i, .disabledOption span {
    color: #AAAAAA;
    cursor: not-allowed;
}

ul.bng-dropdown.container-dash-item-newmenu.sub-container-dash-item-newmenu-last-update .lastUpdateItemDataMainContainer div {
    margin: 0 0 5px;
}

span.newmenu-explorer-action-share {
    float: right;
}

.input-dashboard-item-size {
    width: 60px;
    text-align: right;
}

.monitorKPIBandFor {
    width: 120px;
    text-align: center;
}

ul.bng-dropdown.container-dash-item-newmenu.sub-container-dash-item-newmenu-monitor {
    width: 240px;
}

ul.bng-dropdown.container-dash-item-newmenu.sub-container-dash-item-newmenu-monitor > li {
    display: block;
}

.container-dash-item-more i.material-icons {
    margin: 0 0 0 14px;
    font-size: 18px;
    float: right;
}

ul.bng-dropdown.container-dash-item-newmenu.sub-container-dash-item-newmenu-explorer {
    width: 280px;
}

ul.bng-dropdown.container-dash-item-newmenu.sub-container-dash-item-newmenu-explorer > li {
    cursor: default;
}

.bng-dropdown.container-dash-item-newmenu li.active,
.bng-dropdown.container-dash-item-newmenu[class^='sub-container-dash-item-newmenu'] li:hover {
    background: #EFEFEF;
}

.bng-dropdown.container-dash-item-newmenu.sub-container-dash-item-newmenu-resize li:hover,
.bng-dropdown.container-dash-item-newmenu.sub-container-dash-item-newmenu-monitor li:hover {
    background: #FFFFFF;
    cursor: initial;
}

ul.bng-dropdown.container-dash-item-newmenu.container-dash-item-newmenu-edit {
    width: 230px;
}

ul.bng-dropdown.container-dash-item-newmenu.sub-container-dash-item-newmenu-comments {
    padding: 0;
    width: 0;
    height: 0;
    left: 390px;
    position: absolute;
}

.bng-dropdown.container-dash-item-newmenu li td {
    font-size: 13px;
    line-height: 13px;
    min-height: 13px;
    color: #6e6e6e;
}

.lblShorted {
    float: right;
    border: 1px solid #e6e6e6;
    padding: 2px 4px;
    font-size: 9px;
    border-radius: 3px;
    margin-top: -3px;
}

.lastUpdateItemDataMainContainer {
    text-align: center;
}

.lastUpdateItemData {
    font-size: 14px;
    font-weight: bold;
}

.lastUpdateItemDataLabel {
    font-size: 13px;
    padding: 4px 0 0;
    line-height: 20px !important;
    height: 20px !important;
    font-weight: 400;
}

ul.bng-dropdown.container-dash-item-newmenu.sub-container-dash-item-newmenu-last-update li:hover {
    background: #fff;
}

ul.bng-dropdown.container-dash-item-newmenu[class*='sub-container-dash-item-newmenu'] {
    top: -1px;
    margin-top: 0;
}

input.input-dashboard-item-size, input.input-dashboard-item-size:focus {
    margin: 0;
    background: #fbfbfb;
    color: #222;
    width: 82px;
    text-align: left;
}

.button-div-dashboard-item-size {
    text-align: center;
    margin-top: 6px;
    border: 0;
    box-shadow: 0 -1px 0 0 #ccc;
    padding: 16px;
    width: 100%;
    background-color: transparent;
    color: blue;
    font-size: 13px;
    font-weight: 500;
}

.button-div-dashboard-item-size:hover {
    background: #fbfbfb;
}

ul.bng-dropdown.container-dash-item-newmenu .sub-container-dash-item-newmenu-resize form {
    width: 100%;
}

ul.bng-dropdown.container-dash-item-newmenu .sub-container-dash-item-newmenu-resize label {
    font-size: 13px;
    padding-bottom: 2px;
    font-weight: 500;
}

.sub-container-dash-item-newmenu-resize .resize-item-icon {
    font-size: 16px;
    display: block;
    margin: 4px;
    padding-top: 25px;
}

ul.bng-dropdown.container-dash-item-newmenu.sub-container-dash-item-newmenu-align {
    width: 150px;
}

ul.bng-dropdown.container-dash-item-newmenu.sub-container-dash-item-newmenu-explorer .Icon {
    margin: 0;
}

/*tell popper height to prevent overflow*/
.dashboard-item-popper {
    min-height: 167px;
}

/*handle overflow of children elements*/
ul.bng-dropdown.container-dash-item-newmenu:not(.prevent-children-overflow) ul {
    left: calc(90%);
}

ul.bng-dropdown.container-dash-item-newmenu.prevent-children-overflow ul {
    left: calc(-100% - 4px);
}

ul.bng-dropdown.container-dash-item-newmenu.prevent-children-overflow ul.sub-container-dash-item-newmenu-align {
    left: -144px;
}

ul.bng-dropdown.container-dash-item-newmenu.prevent-children-overflow ul.sub-container-dash-item-newmenu-explorer {
    left: -276px;
}

ul.bng-dropdown .sub-container-dash-item-newmenu-config .selected {
    color: #005dff;
}

.DashboardTheme-WHITE .MenuButtonsContainer,
.DashboardTheme-WHITE .container-dropdown-menu .BngIconButton,
.DashboardTheme-WHITE .MenuButtonsContainer .DrillButtons .BngIconButton,
.DashboardTheme-WHITE .MenuButtonsContainer .DashboardObjectOptsButton {
    background-color: #FFFFFF;
    opacity: 0.85;
    color: #333333;
}

.DashboardTheme-WHITE .container-dropdown-menu .BngIconButton:hover,
.DashboardTheme-WHITE .MenuButtonsContainer .DashboardObjectOptsButton:hover,
.DashboardTheme-WHITE .MenuButtonsContainer .DrillButtons .BngIconButton:hover {
    background-color: #E4E4E4;
    opacity: 0.85;
}

.DashboardTheme-WHITE .container-dropdown-menu .BngIconButton:focus,
.DashboardTheme-WHITE .MenuButtonsContainer .DashboardObjectOptsButton:focus,
.DashboardTheme-WHITE .MenuButtonsContainer .DrillButtons .BngIconButton:focus {
    background-color: #C3C3C3;
    opacity: 0.85;
}

.DashboardTheme-BLACK .MenuButtonsContainer,
.DashboardTheme-BLACK .container-dropdown-menu .BngIconButton,
.DashboardTheme-BLACK .MenuButtonsContainer .DashboardObjectOptsButton,
.DashboardTheme-BLACK .MenuButtonsContainer .DrillButtons .BngIconButton {
    background-color: #333333;
    opacity: 0.85;
    color: #FFFFFF;
}

.DashboardTheme-BLACK .container-dropdown-menu .BngIconButton:hover,
.DashboardTheme-BLACK .MenuButtonsContainer .DashboardObjectOptsButton:hover,
.DashboardTheme-BLACK .MenuButtonsContainer .DrillButtons .BngIconButton:hover {
    background-color: #444444 !important;
    border: none !important;
}

.DashboardTheme-BLACK .container-dropdown-menu .BngIconButton:focus,
.DashboardTheme-BLACK .MenuButtonsContainer .DashboardObjectOptsButton:focus,
.DashboardTheme-BLACK .MenuButtonsContainer .DrillButtons .BngIconButton:focus {
    background-color: #555555;
    opacity: 0.85;
}

.DashboardTheme-CORPORATIVE .MenuButtonsContainer,
.DashboardTheme-CORPORATIVE .container-dropdown-menu .BngIconButton,
.DashboardTheme-CORPORATIVE .MenuButtonsContainer .DashboardObjectOptsButton,
.DashboardTheme-CORPORATIVE .MenuButtonsContainer .DrillButtons .BngIconButton {
    background-color: #3C2B61;
    opacity: 0.85;
    color: #FFFFFF;
}

.DashboardTheme-CORPORATIVE .container-dropdown-menu .BngIconButton:hover,
.DashboardTheme-CORPORATIVE .MenuButtonsContainer .DashboardObjectOptsButton:hover,
.DashboardTheme-CORPORATIVE .MenuButtonsContainer .DrillButtons .BngIconButton:hover {
    background-color: #4D3C72 !important;
    opacity: 0.85;
    border: none !important;
}

.DashboardTheme-CORPORATIVE .container-dropdown-menu .BngIconButton:focus,
.DashboardTheme-CORPORATIVE .MenuButtonsContainer .DashboardObjectOptsButton:focus,
.DashboardTheme-CORPORATIVE .MenuButtonsContainer .DrillButtons .BngIconButton:focus {
    background-color: #5E4D83;
    opacity: 0.85;
}

.RenderablePreload .BngAnalysisDrillDownBar.DrillButtons {
    border-radius: 30px;
}

.MenuButtonsContainer {
    border-radius: 17px;
}

.MenuButtonsContainer,  .RenderablePreload .BngAnalysisDrillDownBar.DrillButtons {
    box-shadow: 0 0 3px 0 rgba(148, 148, 148, 0.6);
    display: flex;
}