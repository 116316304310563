.BngBigIcon .IconWrapper {
  width: 124px;
  height: 124px;
  border-radius: 100%;
  background-color: rgba(7, 127, 231, 0.1);
  border: 2px solid rgba(0, 93, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.BngBigIcon .Icon {
  font-size: 80px;
  color: #005dff;
}

.BngBigIcon .MsgWrapper b {
  font-size: 18px;
}
