.BngDropdownTagsPopper {
  width: 210px;
  max-height: 260px;
  min-height: 220px;
  border-radius: 8px;
  padding: 10px 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 55555;
}

.BngDropdownTagsPopperOverlay {
  z-index: 25050;
}

.BngDropdownTagsPopper .CheckboxTags,
.BngDropdownTagsPopper input[type='checkbox'],
.BngDropdownTagsPopper input[type='radio'] {
  opacity: 0;
  position: absolute;
}

.BngDropdownTagsPopper .colored-ball {
  position: absolute;
  width: 6px;
  height: 6px;
  mix-blend-mode: hard-light;
  border-radius: 100px;
}

.BngDropdownTagsPopper .groupName {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  white-space: nowrap;
}

.BngDropdownTagsPopper .CheckboxItem {
  margin-top: 0.5em;
  cursor: pointer;
  display: flex;
}

.BngDropdownTagsPopper .checkboxLabel {
  content: "";
  cursor: pointer;
  position: absolute;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  color: #fff;
  background: #fff;
  border: 2px solid rgba(31, 31, 31, 0.12);
}

.BngDropdownTagsPopper .radioLabel {
  content: "";
  cursor: pointer;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  color: #fff;
  background: #fff;
  border: 2px solid rgba(31, 31, 31, 0.12);
}

.BngDropdownTagsPopper .itemInputLabel::after {
  content: "";
  position: absolute;
  display: none;
}

.BngDropdownTagsPopper input:checked ~ .itemInputLabel::after {
  display: block;
}

/* Radio Input style */
.BngDropdownTagsPopper .radioLabel::after {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: solid white;
  opacity: 1;
}

/* Checkbox Input style */
.BngDropdownTagsPopper .checkboxLabel::after {
  left: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 1;
}

.BngDropdownTagsPopper input:checked ~ .itemInputLabel {
  background: #005DFF;
}

.BngDropdownTagsPopper .checkboxItemRender {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 188px;
  white-space: nowrap;
}

.BngDropdownTagsPopper .BngDropdownTagsAll {
  width: 17px;
  height: 17px;
  margin-top: 8px;
  position: relative;
  cursor: pointer;
}

.BngDropdownTagsPopper .bng-search.open {
  box-shadow: none;
}

.BngDropdownTagsPopper .ActionListSearch {
  box-shadow: none;
}

.BngDropdownTagsPopper .EmptyCreateTag {
  color: #005dff;
}

.BngDropdownTagsPopper .EmptyCreateTagText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.BngDropdownTagsPopper .Icon .li {
  margin-right: 15px;
  vertical-align: middle;
  width: 16px;
}

.BngDropdownTagsPopper .Icon {
  font-size: 20px;
}

.BngDropdownTagsPopper .li {
  font-size: 16px;
}

.BngDropdownTagsPopper .tagsFooterActions .tagsFooterButton {
  align-items: center;
  box-shadow: inset 0 -1px 0 0 #e7e8eb;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  line-height: 30px;
  justify-content: center;
  transition: transform 0.2s;
  width: 100%;
}

.BngDropdownTagsPopper .tagsClearSelection {
  border: 1px solid #6C6C6C;
}

.BngDropdownTagsPopper .tagsClearSelection.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background-color: #e7e8eb;
}

.BngDropdownTagsPopper .tagsFooterActions .tagsFooterButton:hover {
  transform: scale(1.02);
}

.BngDropdownTagsPopper .tagsFooterActions .tagsClearSelection:hover {
    background-color: #eee;
}

.BngDropdownTagsPopper .tagsFooterActions .tagsApplySelection:hover {
    background-color: #506dea !important;
}

.BngDropdownTagsPopper .tagsFooterActions .tagsApplySelection {
  background: #005dff;
  color: #FFFFFF;
}

.BngDropdownTagsPopper .tagsFooterButton .Icon{
  margin-right: 6px;
  vertical-align: middle;
  width: 16px;
}

.BngDropdownTagsPopper .tagsFooterActions {
  display: inline-flex;
  gap: 5px;
  width: 100%;
}

.BngDropdownTagsPopper .tagsNotFound {
  padding: 10px 0;
  position: relative;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  width: 100%;
  justify-content: center;
  color: #6e6e6e;
}