.headerButtons :global(.bng-search) {
  height: 40px;
}

.logsSearch {
  width: 100%;
  margin-left: 6px;
}

:global(.BngTable) .tableLeftPadding {
  padding-left: 20px;
}

.flexBoxCenterContainer {
  display: flex;
  align-items: center;
}

.flexBoxCenterContainer .bngTags {
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 2px 12px;
  gap: 0;
}

.flexBoxCenterContainer .SUCCESS {
  background-color: #21a55f;
}

.flexBoxCenterContainer .ERROR {
  background-color: #e23d3d;
}

.flexBoxCenterContainer .NEW_DATA_NOT_FOUND,
.flexBoxCenterContainer .NOT_FINISHED,
.flexBoxCenterContainer .NOT_STARTED {
  background-color: #f89406;
}

.flexBoxCenterContainer .RUNNING {
  padding: 2px 12px 2px 6px;
}

.IconSuccess {
  color: #21a55f;
}

.IconError {
  color: #e23d3d;
}

.logFooterContainer {
  width: 100%;
  margin-top: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logFooterButton {
  margin-left: auto;
}

.cancelLoadButton :global(i) {
  color: #e23d3d;
}