.AccountsPage {
  left: 0;
  min-height: calc(100vh - 84px);
  position: absolute;
  width: 100%;
}

.AccountsPage .accountsBlocker {
  min-height: calc(100vh - 84px);
  height: 100%;
}

.navPanelWrapper {
  display: inline-flex;
  height: calc(100vh - 230px);
  width: 100%;
}

.NavPanel {
  background: #ffffff;
  border-right: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 300px;
}

.AccountSelect {
  align-items: center;
  background: #f6f6f6;
  border-top: 1px solid #e4e4e4;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  height: 60px;
  padding: 6px;
}

.accountName {
  align-items: center;
  display: flex;
  font-weight: bold;
  justify-content: center;
}

.accountTabs {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  width: 100%;
}

.tabItem,
.tabChildren {
  align-items: center;
  color: #555555;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  justify-content: space-between;
  width: 100%;
}

.tabItem {
  border-left: 5px solid transparent;
  width: calc(100% - 5px);
}

.tabItem:hover,
.tabChildren:hover {
  background: #dce9f8;
}

.selectedTab {
  border-left-color: var(--blue-default);
}

.tabTitle {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  padding: 10px 8px 10px 24px;
}

.tabItem .tabIcon {
  color: var(--blue-default);
}

.tabItem :global(.BngIconButton) {
  margin-right: 20px;
}

.tabContent {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}

.tabChildren.lastChild {
  border-bottom: 1px solid #e4e4e4;
}

.tabChildren span {
  border-left: 1px dashed #d1d1d1;
  margin-left: 42px;
  padding: 10px 0 10px 16px;
}

.tabChildren.notVisible {
  display: none;
}

.tabItem:global(.disabled) {
  color: #c2c1c1 !important;
  background-color: unset !important;
}

.tabItem:global(.disabled) i {
  color: #c2c1c1 !important;
}

.tabItem:global(.disabled):hover {
  cursor: not-allowed;
}
.accountsSpinner svg {
  width: 70px;
  height: 70px;
}

.AccountsPageAda :global(.wrapperAdaClosed),
.AccountsPageAda :global(.AdaOpenHandImg) {
  margin-right: 0 !important;
}

.AccountsPageAda :global(.contentContainer) {
  margin-bottom: 185px !important;
  margin-right: 157px !important;
}

.tutorialStepTitle {
  align-items: center;
  display: inline-flex;
  gap: 5px;
}

.AccountsPageMultiStepInfo {
  background: linear-gradient(90deg, #1e3ada 0%, #049cf4 100%);
  border-radius: 8px;
  height: 450px;
  overflow: hidden;
}

.AccountsPageMultiStepInfo :global(.Title),
.AccountsPageMultiStepInfo :global(.Subtitle) {
  color: #fff !important;
}

.AccountsPageMultiStepInfo :global(.BngTag.betaTag) {
  border-color: #fff;
  color: #fff;
}

.AccountsPageMultiStepInfo :global(.widget-footer-pg-button.active) {
  background-color: #fff;
}

.AccountsPageMultiStepInfo :global(.widget-footer-pg-button) {
  background-color: #005dff;
}

.AccountsPageMultiStepInfo :global(.Buttons .bng-button.enable) {
  background: transparent !important;
  border-radius: 8px;
  color: #fff !important;
}

.AccountsPageMultiStepInfo :global(.Buttons .bng-button.enable):hover {
  background-color: rgba(255, 255, 255, 15%) !important;
}

.AccountsPageMultiStepInfo :global(.bng-button.save) {
  font-weight: bold;
}

.tutorialLink {
  color: #fff;
  font-weight: bold;
  text-decoration: underline !important;
}

.tutorialLink:hover,
.tutorialLink:focus {
  color: #fff;
}
