.ContainerMenuPopper {
    width: 230px;
    padding: 4px 0;
}

.ContainerMenuPopper .bng-dropdown {
    padding: 4px 0;
}

.container-dropdown-menu.without-title {
    position: fixed;
    top: -12px;
    right: -12px;
    z-index: 1;
    cursor: pointer;
    text-align: right;
}

.container-dropdown-menu .drop-button {
    cursor: pointer;
}

.container-dropdown-menu.with-title {
    z-index: 1;
    width: calc(100% - 20px);
    box-shadow: inset 0 -1px 0 0 #E7E8EB;
    display: inline-flex;
    align-items: center;
    padding: 4px 10px;
}

.container-dropdown-menu.blank-icon-and-title {
    z-index: 1;
    width: calc(100% - 20px);
    display: inline-flex;
    align-items: center;
    padding: 4px 10px;
}

.container-dropdown-menu {
    height: 32px;
}

.container-dropdown-menu .BngIconButton {
    padding: 7px !important;
}

.container-dropdown-menu .icon-dropdown {
    position: relative;
    border-radius: 100%;
    z-index: 1;
}

.bng-dropdown-parent.ContainerMenuPopper li > .Icon {
    width: 1em;
}

.container-dropdown li {
    display: block;
    padding: 10px 15px;
    font-weight: 500;
    color: #6e6e6e;
    cursor: pointer;
    line-height: 30px;
    border-bottom: 1px solid #ddd;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 175px;
    overflow: hidden;
}

.container-dropdown li .BngSwitch .switch-label {
    padding: 8px 0 8px 22px;
}

.container-dropdown li .BngSwitch .switch-title {
    width: 133px;
    top: 5px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.container-dropdown li:last-child {
    border-bottom: 0;
}

.container-dropdown li:hover {
    background: #EFEFEF;
}

.container-dropdown-menu.edit-mode .container-title {
    width: calc(100% - 32px);
}

.container-title {
    width: 100%;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
}

.container-title .Icon {
    padding-right: 10px;
}

.container-title span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.container-dropdown-menu .iconDescription {
    margin-left: 5px;
    cursor: pointer;
}

.container-configure-title-body {
    padding: 20px;
    background-color: #f6f6f6;
}

.container-configure-title-body .BngSwitch {
    width: calc(100% - 170px);
}

.container-configure-title-footer {
    padding: 10px 10px 0;
}

.ContainerMenuTitleDialog {
    top: calc(50% - 248px);
}

.ContainerMenuTitleDialog .widget-header {
    text-transform: uppercase;
}

.container-dropdown-menu-parent .bng-dropdown {
    width: 210px;
}

.container-dropdown-menu-parent .bng-dropdown .switch-title {
    font-weight: 500;
}

.container-dropdown-menu-parent .bng-dropdown li .material-icons {
    width: 24px;
}

.ContainerMenuPopper ul li {
    display: flex;
    align-items: center;
    box-shadow: none;
    line-height: 13px;
    font-size: 13px;
}

.ContainerMenuTitleDialog textarea {
    height: 200px;
    word-break: break-word;
    text-align: justify;
}