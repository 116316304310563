.BngAdaBackground {
  align-items: center;
  display: flex;
  justify-content: center;
}

.BngAda .contentContainer {
  --green: #40c102;
  --light-green: #3dd705;
  width: fit-content;
  max-width: 500px;
  position: fixed;
  will-change: transform;
  bottom: 0;
  right: 0;
  margin-right: 620px;
  margin-bottom: 220px;
  z-index: 100;
}

.BngAda .contentContainer.adaContentFixedSize {
  width: 500px;
  height: 310px;
}

.BngAda .contentWrapper.adaContentFixedSize {
  height: 310px;
}

.BngAda .contentContainer h1 {
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;

  margin: 0 0 20px 0;

  color: #ffffff;
}

.BngAda .contentContainer span {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

.BngAda .optionsContainer {
  margin-top: 15px;
  display: grid;
}

.BngAda .optionsContainer span {
  font-weight: bold !important;
  font-size: 16px;
  line-height: 19px;
  margin-left: 10px;
  text-decoration: underline !important;
}

.BngAda .optionsContainer a {
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 5px;
}

.BngAda .contentContainer .footerWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.BngAda .contentContainer button {
  height: 48px;
}

.BngAda .contentContainer button.mainButton {
  width: 140px;

  background: var(--green);
  transition: background-color 300ms ease-out;
  border-radius: 100px;
  border: none;
  padding: 0;

  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  color: #ffffff;

  cursor: pointer;
}

.BngAda .contentContainer button.mainButton:hover {
  background: var(--light-green);
}

.BngAda .contentContainer button.mainButton:disabled {
  background: linear-gradient(0deg, #d1d1d1, #d1d1d1), #ffffff;
  border-radius: 15px 15px 0 15px;
  cursor: not-allowed;
}

.BngAda .contentContainer button.leftButton {
  background-color: transparent;
  border: 0;
  border-radius: 100px;
  color: #ffff;
  font-size: 16px;
  line-height: 19px;
  padding: 0;
  transition: text-shadow 300ms ease-out;
}

.BngAda .contentContainer button.leftButton:hover {
  text-shadow: 0 0 10px #ffffff82;
}

.BngAda .contentWrapper {
  background: linear-gradient(120deg, rgba(35, 41, 214, 1) 0%, rgba(0, 172, 248, 1) 100%);
  border-radius: 15px 15px 0 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 35px;
}

.BngAda img.AdaOpenHandImg {
  height: 185px;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 3000;
  margin-right: 438px;
}

.BngAda img.AdaClosedHandImg {
  height: 100px;
  width: auto;
  position: relative;
  top: -10px;
  left: -5px;
}

.BngAda .enableInfo {
  text-decoration: none;
  cursor: pointer;
}

.BngAda .wrapperAdaClosed {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  border-top-left-radius: 80px;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 48;
  margin-right: 394px;
}

.BngAda .disableInfo,
.BngAda .disableBackground {
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}

.bngAdaTagsWrapper {
  display: inline-flex;
  gap: 5px;
}

.bngAdaTag {
  border: 1px solid #fff;
}

.BngAda .BngAdaGreyBackground {
  background: linear-gradient(118.1deg, #3d3d3d 0%, #565656 100%);
}

.bngAdaGreyTag {
  background: transparent;
}

.AdaStepIndicator {
  align-items: center;
  bottom: -25px;
  display: inline-flex;
  gap: 5px;
  justify-content: center;
  position: absolute;
  height: 12px;
  width: 50px;
}

.AdaStepIndicatorBall {
  background: #dcdcdc;
  border-radius: 100%;
  height: 12px;
  width: 12px;
}

.AdaStepIndicatorBall.AdaStepActive {
  background: #005dff;
}

.BngAdaBackground .BngAdaBackgroundSteps {
  background: linear-gradient(120deg, rgba(35, 41, 214, 1) 0%, rgba(0, 172, 248, 1) 100%);
  border-radius: 15px 15px 0 15px;
  height: 100%;
  position: absolute;
  width: 100%;
}

@media (min-width: 1610px) {
  .BngAda .wrapperAdaClosed {
    margin-right: calc(20% + 10px);
  }

  .BngAda .wrapperAdaOpen img {
    margin-right: calc(20% + 50px);
  }

  .BngAda .contentContainer {
    margin-right: calc(20% + 202px);
  }
}
