.manage-environment-menu.dropdown-menu {
    width: 560px;
}

.manage-environment-menu.dropdown-menu .nav-header {
    background-color: #ecf2f700 !important;
    color: #333333 !important;
    border-bottom: 1px solid #dddddd;
}

.manage-environment-menu.dropdown-menu .nav-header .icon-plus {
    display: none;
}

.manage-environment-itens-container-div {
    width: 280px;
}

.manage-environment-itens-container-div > div:last-child {
    border-bottom: 0;
}

.manage-environment-itens-container {
    width: 560px;
    height: 425px;
    padding: 6px 10px;
    float: left;
    border-bottom: 1px solid #ededed;
}

.manage-environment-itens-container .menu_sttings {
    cursor: pointer !important;
    width: 256px !important;
}

.manage-environment-itens-container:hover {
    background: #ffffff;
}

.manage-environment-itens-container table tr td {
    vertical-align: top;
    padding: 0;
}

.create-object-item-container.userandgroups {
    border-left: 4px solid #528DE3;
    height: 79px;
    cursor: pointer;
}


.create-object-item-container.folderandpermissions {
    border-left: 4px solid #EB354D;
    height: 79px;
    cursor: pointer;
}

.create-object-item-container.editcockpits {
    border-left: 4px solid #269C59;
    height: 79px;
    cursor: pointer;
}

.create-object-item-container.dataandintegration {
    border-left: 4px solid #E9B23A;
    height: 79px;
    cursor: pointer;
}


.create-object-item-container.editsettings {
    border-left: 4px solid #AC4FC6;
    height: 79px;
    cursor: pointer;
}

.create-object-item-container.dumpdelete {
    border-left: 4px solid #16879E;
    height: 79px;
    cursor: pointer;
}

.pie-chart-configure-metrics {
    width: 150px;
    height: 150px;
    padding-left: 20px;
    padding-top: 20px;
}

.pie-chart-legend-metrics {
    width: 280px;
    margin-top: 39px;
    padding-left: 40px;
}

.label-legend-pie-chart {
    height: 35px;
    width: 116px;
    color: #595959;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
}

.user-count-metric {
    height: 22px;
    width: 120%;
    border-radius: 11px;
    text-align: center;
    margin-top: 13px;
    min-width: 33px;
    color: #FFFFFF;
    font-weight: bold;
}

.container-legend-pi-chart {
    float: right;
    padding-right: 43px;
    margin-top: -156px;
}

.container-legend-object-pi-chart {
    float: right;
    padding-right: 43px;
    margin-top: -227px;
}


.icon-text-metrics {
    font-weight: bold;
    font-size: 36px;
    padding-left: 10px;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial !important;
}

.icon-text-metrics-inside-pie {
    font-weight: bold;
    font-size: 21px;
    padding-left: 7px;
    padding-top: 15px;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial !important
}

.pie-chart-configure-objects-metrics {
    width: 150px;
    height: 150px;
    padding-left: 20px;
    padding-top: 48px;
}

.pie-chart-legend-object-metrics {
    width: 280px;
    margin-top: 26px;
    padding-left: 40px;
}

.bng-dropdown-stats-users:before {
    position: absolute;
    top: -39px;
    left: 219px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #FFFFFF;
    border-left: 7px solid transparent;
    border-bottom-color: #ffffff;
    content: '';
}

.bng-dropdown-stats-object:before {
    position: absolute;
    top: -39px;
    left: 219px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #FFFFFF;
    border-left: 7px solid transparent;
    border-bottom-color: #ffffff;
    content: '';
}

.bng-dropdown-stats-quota:before {
    position: absolute;
    top: -39px;
    left: 130px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #FFFFFF;
    border-left: 7px solid transparent;
    border-bottom-color: #ffffff;
    content: '';
}