.MoveObjectParent {
  position: fixed;
  bottom: 50px;
  opacity: 1;
  z-index: 350;
  left: calc(50% - 225px);
  border-radius: 100px;
  animation: softComing 0.5s ease-out;
}

@keyframes softComing {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.MoveObjectParent .save-snackbar .bng-snackbar-icon,
.MoveObjectParent .save-snackbar .bng-snackbar-message {
  cursor: not-allowed;
}

.MoveObjectParent .bng-snackbar .cancel-move-object {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.free-style-marker-class.OnGridCreation .DashGrid .DashGridItem.select-item-to-move {
  box-sizing: border-box;
  border: 2px dashed #005dff !important;
  opacity: 1 !important;
}

.free-style-marker-class.OnGridCreation .DashboardTheme-WHITE .DashGrid .DashGridItem.select-item-to-move {
  background-color: whitesmoke;
}

.free-style-marker-class.OnGridCreation .DashboardTheme-BLACK .DashGrid .DashGridItem.select-item-to-move,
.free-style-marker-class.OnGridCreation .DashboardTheme-CORPORATIVE .DashGrid .DashGridItem.select-item-to-move {
  background-color: #111111;
}

.DashboardTheme-WHITE .select-item-to-move .grid-stack-item-content,
.DashboardTheme-WHITE .select-item-to-move .widget-box,
.DashboardTheme-WHITE .select-item-to-move .widget-body {
  background-color: white;
}

.MoveObjectOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  opacity: 0.3;
  z-index: 250;
}

.free-style-marker-class.OnGridCreation {
  z-index: 300 !important;
}

.free-style-marker-class.OnGridCreation .dash-item-newmenu,
.free-style-marker-class.OnGridCreation .icon-dropdown,
.free-style-marker-class.OnGridCreation .iconDescription {
  visibility: hidden;
}

.free-style-marker-class.OnGridCreation .item-content-container {
  background-color: transparent;
}

.free-style-marker-class.OnGridCreation .DashGridItem.Container {
  opacity: 0.3;
}

.free-style-marker-class.OnGridCreation .DashGrid .DashGridItem.react-resizable {
  border: 2px dashed #828282;
  opacity: 0.8;
}

.free-style-marker-class.OnGridCreation .DashGrid .DashGridItem.react-resizable .widget-box .widget-body {
  border-color: transparent;
}

.free-style-marker-class.OnGridCreation .container-dropdown-menu .drop-button {
  display: none;
}

.free-style-marker-class .dashboard-page-break-filler {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
}

.free-style-marker-class.OnGridCreation .dashboard-page-break-filler {
  display: block;
}
