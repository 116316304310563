.DashboardLayoutAccordion #ProportionsAccordion .BngField {
    margin-bottom: 0;
}

.ApplyDashLayout {
    position: fixed;
    bottom: 0;
    right: 51px;
    min-width: 340px;
    height: 55px;
    border-radius: 0;
    z-index: 50;
}

.ApplyDashLayout .AccordionContent {
    padding: 10px;
}

.ApplyDashLayout .AccordionContent button.bng-button {
    width: 100%;
    margin: 0;
}

.DashboardLayoutAccordion .CheckInputOption {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.DashboardLayoutAccordion .CheckInputOption > .BngInput {
    width: 58px;
}

.DashboardLayoutAccordion .CheckInputOption > * {
    margin-bottom: 0;
}

.DashboardLayoutAccordion .TransparencyField label {
    display: flex;
    align-items: center;
}
.DashboardLayoutAccordion #AppearanceAccordion .AccordionContent > .BngCheckbox {
    height: 34px;
    display: flex;
    align-items: center;
}

.DashboardLayoutAccordion .HighlightColor {
    margin-bottom: 0;
}

.DashboardLayoutAccordion .HighlightColor .BngDropdown {
    width: 100%;
}