.FolderContainer {
    display: flex;
    align-items: center;
}

.FolderContainer > div:first-child {
    flex-grow: 1;
}

.FolderContainer .folder-root-field {
    width: 100%;
    margin: 0;
}

.FolderContainer .folder-field {
    cursor: pointer;
    width: 100%;
}

.FolderContainer .folder-field.disabled {
    cursor: not-allowed;
}

.FolderContainer.Required.HasErrors .folder-field .FieldPreview {
    border-color: #d16e6c;
}

.FolderContainer.Required .folderTitleLabel:after {
    color: #d16e6c;
    content: "*";
    padding-left: 2px;
}

.FolderContainer.Required.HasErrors .folderTitleLabel {
    color: #d16e6c;
    content: "*";
    padding-left: 2px;
}

.FolderContainer .button-create-folder {
    align-items: center;
    background-color: #3558EF !important;
    border-radius: 2px;
    display: flex;
    height: 38px;
    margin-left: 10px;
    padding: 2px 12px;
    width: 50px;
}

.dashboard-folder-input .button-create-folder,
.kpi-wizard-step-container .button-create-folder {
    align-items: center;
    height: 40px;
}