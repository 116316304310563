.BimQueryPage {
  position: relative;
  height: 100%;
}

.BimQueryRightMenuWrapper {
}

.BimQueryRightMenuWrapper :global(#ObjectRightMenuAccordionWrapper) {
  position: static;
}

.BimQueryRightMenuWrapper :global(#ObjectRightMenuAccordionWrapper .AccordionWrapper.ObjectRightMenuAccordion) {
  position: absolute;
  height: 90vh;
}

.BimQueryPage :global(.UiBlocker.menu-opened) {
  width: calc(100% - 395px);
}

.BimQueryPage :global(.UiBlocker.menu-opened.Loading) {
  width: auto;
}

.BimQueryPage :global(.UiBlocker.menu-closed) {
  width: calc(100% - 55px);
}

.ActionsButtons:global(.menu-closed) {
  right: calc(0% + 84px);
}

.ActionsButtons:global(.menu-opened) {
  right: calc(0% + 428px);
}

.ActionsButtons:global(.menu-opened),
.ActionsButtons:global(.menu-closed) {
  align-content: center;
  display: flex;
  margin-top: 1vh;
  flex-wrap: wrap;
  align-items: center;
}

.ExecuteQueryButton {
  background-color: #005dff;
  border-radius: 12px;
}

.ExecuteQueryButton,
.ExecuteQueryButton i {
  font-size: 16px !important;
  color: #ffffff;
  height: 22px;
  width: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ActionsButtons :global(.validate-query) {
  background-color: #00a355;
  padding: 2px 8px 2px 8px;
  border-radius: 12px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  margin-right: 16px;
}

.ActionsButtons :global(.validate-query):hover {
  background-color: rgba(0, 163, 85, 1);
}

.ActionsButtons :global(.validate-query) i {
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-right: 5px;
}

.QueryResultLinesAmountPopper {
  z-index: 99;
  width: auto;
}

.QueryResultLinesAmountPopper ul li {
  display: flex;
  flex-direction: row-reverse;
  padding: 0 0 0 20px;
  justify-content: space-between;
}

.QueryResultLinesAmountPopper ul li :last-child {
  margin-right: 10px !important;
}

.QueryResultLinesAmountPopper:global(.Overlay) {
  z-index: 99;
  opacity: 0;
}

.QueryResultPreviewTab {
  height: 35vh;
}

.CenterButtonsWrapper {
  height: 4vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.QueryActionButtons {
}

.ExportViewButton {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #337dff;
}

.ExportViewButton:hover {
  cursor: pointer;
}

.ExportViewButton i {
  color: #337dff;
  height: 15px;
  width: 15px;
}

.ColumnsConfigurationTab {
  height: 32vh;
}
