.NameIconWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.NameIconWrapper div:global(.BngField) {
  width: 26vh;
}

.NameIconWrapper i {
  color: #005dff;
  background-color: white;
  height: 35px;
  width: 35px;
  border: 1px solid #d1d1d1;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TagSelection {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 12px;
}

.TagSelection div {
  height: 5px;
  width: 5px;
  border-radius: 3px;
  background-color: #005dff;
  margin-right: 5px;
}

.TagSelectorWrapper {
}

.StructureTypeTitle {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
}

.StructureType,
.StructureType:global(.selected) {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  width: 145px;
  height: 38px;
  border-radius: 8px;
  background-color: white;
  position: relative;
}

.StructureType {
  border: 1px solid #d1d1d1;
}

.StructureType:global(.selected) {
  border: 1px solid #005dff;
  color: #005dff;
}

.StructureType i {
  margin-right: 5px;
  font-size: 20px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  margin-left: 12px;
}

.StructureType i {
  color: #555555;
}

.StructureType:global(.selected) i {
  color: #005dff;
}

.StructureType div:global(.BngRadio.BngCheckbox) {
  position: absolute;
  right: 0;
}

.StructureTypeWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.GoBackButton {
  left: 1vh !important;
  background-color: #555555;
}

.NextStepButton,
.GoBackButton {
  position: absolute;
  bottom: 1vh;
  right: 1vh;
  width: 150px;
}
