.BngCockpitView {
  height: 100%;
}

.BngCockpitView #cockpit-nav-header {
  background-color: #ffffff;
  width: 100%;
  height: 36px;
}

.BngCockpitView .cockpit-item-panel {
  height: calc(100% - 36px) !important; /*-36px from Cockpit navbar*/
  overflow-x: hidden !important;
}

.BngCockpitView .LeafletMap,
.BngCockpitView .LeafletMap .map-container {
  height: 100% !important;
}

.BngCockpitView .HtmlItemContainer {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.BngCockpitView .CockpitBreadcrumb {
  z-index: 5;
  left: 45px;
  width: calc(100% - 500px) !important;
}

.BngCockpitView .CockpitBreadcrumb .breadcrumb {
  width: calc(100% - 36px);
  margin: 0;
}

.BngCockpitView .CockpitBreadcrumb .breadcrumb-logo {
  width: 36px;
}

.BngCockpitView .CockpitBreadcrumb .breadcrumb-logo .Icon {
  margin-left: 8px;
}

.BngCockpitView .background-loading-opacity {
  z-index: 10;
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.2);
}
